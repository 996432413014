/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { hot } from 'react-hot-loader/root';
import { Provider } from 'react-redux';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import ReactGA from 'react-ga';
import { SnackbarProvider } from 'notistack';

import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Player from './components/Player';
import ToolModal from './components/ToolModal';
import SocketManager from './components/SocketManager';

import colors from './libs/colors';
import store from './redux/store';

import './calibration.css';
import './typcns.css';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: colors.lightBlue,
      main: colors.blue,
      dark: colors.deepBlue,
      contrastText: colors.contrastText,
    },
    secondary: {
      main: colors.drawerBackground,
      contrastText: colors.contrastText,
    },
  },
  status: {
    error: colors.red,
    danger: colors.amber,
    success: colors.green,
  },
  typography: {
    fontFamily: [
      'Montserrat',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontWeight: 600,
    },
    h2: {
      fontWeight: 600,
    },
    h3: {
      fontWeight: 600,
    },
    h4: {
      fontWeight: 600,
    },
    h5: {
      fontWeight: 600,
    },
    h6: {
      fontWeight: 600,
    },
  },
});

ReactGA.initialize([
  {
    trackingId: 'UA-168989029-1',
    gaOptions: { name: 'internal' },
  },
]);

class EkyCalibrationTool extends Component {
  render() {
    const {
      arid = window.EKYCalibration.arid || null,
      product = window.EKYCalibration.product || null,
      project = window.EKYCalibration.project || null,
      checksum = window.EKYCalibration.checksum || null,
      onReport,
      onClose,
      onSaveSuccess,
      onSaveError,
    } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <Provider store={store}>
            <div className="eyekandy-calibration-tool">
              <SocketManager onSaveSuccess={onSaveSuccess} onSaveError={onSaveError} />
              <Header arid={arid} product={product} project={project} />
              <Sidebar />
              <Player arid={arid} checksum={checksum} />
              <ToolModal checksum={checksum} arid={arid} onClose={onClose} onReport={onReport} />
            </div>
          </Provider>
        </SnackbarProvider>
      </ThemeProvider>
    );
  }
}

export default process.env.NODE_ENV === 'development' ? hot(EkyCalibrationTool) : EkyCalibrationTool;
