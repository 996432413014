import { set, findIndex } from 'lodash';
import {
  SET_CONFIG,
  SET_CONFIG_SINGLE,
  SET_HOTSPOTS,
  SET_HOTSPOT,
} from '../actionTypes';

const initialState = {
  lights: {
    directional: {
      light01: {
        direction: {
          x: 0,
          y: -1,
          z: 0.5,
        },
        intensity: 0.21,
      },
      light02: {
        direction: {
          x: 0.8,
          y: -1,
          z: -0.5,
        },
        intensity: 0.23,
      },
      light03: {
        direction: {
          x: -0.8,
          y: -1,
          z: -0.5,
        },
        intensity: 0.27,
      },
    },
    ambient: {
      direction: {
        x: 0,
        y: 0.4,
        z: 1,
      },
      intensity: 0.23,
    },
  },
  environment: {
    map: 3,
    amount: 0.9,
    groundY: 0.00074,
    arrowSize: 1.25,
    seeUnder: true,
  },
  shadows: {
    darkness: 0.5,
    blur: 64,
  },
  camera: {
    startPos: {
      alpha: 0.91076,
      beta: 1.04775,
      radius: 0.16618,
    },
  },
  post: {
    ao: {
      falloff: 0.000001,
      area: 0.01,
      radius: 0.00005,
      strength: 1.35,
      base: 1,
    },
    bloom: {
      enabled: true,
      weight: 0.2,
    },
    image: {
      contrast: 1,
      exposure: 1,
    },
  },
  hotspots: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CONFIG: {
      return {
        ...action.payload,
      };
    }

    case SET_CONFIG_SINGLE: {
      const { mapping, value } = action.payload;
      const stateRemapped = set(state, mapping, value);
      return {
        ...stateRemapped,
        ...state,
      };
    }

    case SET_HOTSPOTS: {
      const { hotspots } = action.payload;

      return {
        ...state,
        hotspots,
      };
    }

    case SET_HOTSPOT: {
      const { hotspot } = action.payload;

      const index = findIndex(state.hotspots, { uuid: hotspot.uuid });

      const hotspots = [...state.hotspots];

      if (index === -1) {
        hotspots.push(hotspot);
      } else {
        hotspots[index] = hotspot;
      }

      return {
        ...state,
        hotspots,
      };
    }

    default:
      return state;
  }
}
