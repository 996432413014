export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const SET_MODAL_MESSAGE = 'SET_MODAL_MESSAGE';
export const SET_CONFIG = 'SET_CONFIG';
export const SET_CONFIG_SINGLE = 'SET_CONFIG_SINGLE';
export const SET_HOTSPOTS = 'SET_HOTSPOTS';
export const SET_HOTSPOT = 'SET_HOTSPOT';
export const HELP_DISPLAYED = 'HELP_DISPLAYED';
export const SET_HELP_STEP = 'SET_HELP_STEP';
export const SEND_SOCKET = 'SEND_SOCKET';
export const CLEAR_SOCKET = 'CLEAR_SOCKET';
export const SET_SIDEBAR = 'SET_SIDEBAR';
