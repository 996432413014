import React from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as Cookies from 'es-cookie';

import GradientButton from './GradientButton';
import HelpPopper from './HelpPopper';
import colors from '../libs/colors';
import {
  openModal,
  setModalMessage,
  setSidebar,
  setHelpStep,
} from '../redux/actions';

const useStyles = makeStyles((theme) => ({
  drawerContainer: {
    padding: '1.5rem',
    overflow: 'auto',
    paddingTop: 0,
  },
  divider: {
    marginTop: '1.25rem',
    marginBottom: '1.25rem',
    backgroundColor: colors.inputGrey,
    opacity: 0.3,
  },
  button: {
    width: '100%',
    textTransform: 'capitalize',
    textAlign: 'left',
  },
  white: {
    color: colors.contrastText,
    margin: theme.spacing(0, 0, 2),
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2, 0),

    '& button': {
      margin: theme.spacing(1, 0),
    },
  },
}));

function SidebarInitial(props) {
  const classes = useStyles();
  const { ui } = props;
  const tutorialPlayed = Cookies.get('tutorial');

  return (
    <div className={classes.drawerContainer}>

      <Typography variant="h6" weight="600" className={classes.white}>
        Welcome to the Eyekandy Calibration Tool
      </Typography>
      <Typography variant="body1" className={classes.white}>
        Here is your 3D/AR model. It has already been calibrated by our experts and is ready for your approval for distribution. If you like the model and are ready to move to the distribution step, please click the 'Continue' button. If you would like to make adjustments to lighting, brightness, contrast, reflection and shadow settings, please click the 'Manually Calibrate Model' button. 
      </Typography>

      <div className={classes.buttonContainer}>
        <HelpPopper
          x="-20"
          y="35"
          open={ui.helpStep === 'initial-1' && !tutorialPlayed}
          text="Click this button to save and propagate to syndication the current calibration settings from our experts"
          onClick={() => {
            props.setHelpStep('initial-2');
          }}
        />
        <GradientButton
          color="green"
          onClick={() => {
            props.setModalMessage('SAVE_CONFIRM');
            props.openModal();
          }}
        >
          Continue
        </GradientButton>

        <HelpPopper
          x="-20"
          y="35"
          open={ui.helpStep === 'initial-2'}
          text="This button will open the Manual Calibration tool and will allow you to manually apply parameters that meet your preference for your model"
          onClick={() => {
            props.setHelpStep(null);
          }}
        />
        <GradientButton
          color="blue"
          onClick={() => {
            props.setSidebar('sliders');
            if (!tutorialPlayed) {
              props.setHelpStep(0);
            }
          }}
        >
          Manually Calibrate Model
        </GradientButton>
      </div>

    </div>

  );
}

export default connect(
  (state) => ({ ui: state.ui }),
  {
    openModal,
    setModalMessage,
    setSidebar,
    setHelpStep,
  },
)(SidebarInitial);
