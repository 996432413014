import {
  OPEN_MODAL,
  CLOSE_MODAL,
  SET_MODAL_MESSAGE,
  SET_HELP_STEP,
  SET_SIDEBAR,
} from '../actionTypes';

const initialState = {
  sidebar: 'initial',
  modal: false,
  message: null,
  debug: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case OPEN_MODAL: {
      return {
        ...state,
        modal: true,
      };
    }

    case CLOSE_MODAL: {
      return {
        ...state,
        modal: false,
      };
    }

    case SET_MODAL_MESSAGE: {
      const { message } = action.payload;
      let { debug = null } = action.payload;

      if (debug !== null) {
        debug = `${Date.now()}._${btoa(JSON.stringify(window.EKYCalibration))}|${btoa(debug)}`;
      }

      return {
        ...state,
        message,
        debug,
      };
    }

    case SET_HELP_STEP: {
      const { helpStep } = action.payload;
      return {
        ...state,
        helpStep,
      };
    }

    case SET_SIDEBAR: {
      const { sidebar } = action.payload;
      return {
        ...state,
        sidebar,
      };
    }

    default:
      return state;
  }
}
