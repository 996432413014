const envmaps = [
  {
    name: 'Lightroom 1',
    path: 'https://media.pointandplace.com/player/images/environment/lightroom-03.env',
  },
  {
    name: 'Lightroom 2',
    path: 'https://media.pointandplace.com/player/images/environment/lightroom-02.env',
  },
  {
    name: 'Lightroom 3',
    path: 'https://media.pointandplace.com/player/images/environment/lightroom-01.env',
  },
  {
    name: 'Studio (Default)',
    path: 'https://media.pointandplace.com/player/images/environment/studio.env',
  },
  {
    name: 'Outside',
    path: 'https://media.pointandplace.com/player/images/environment/outside.env',
  },
  {
    name: 'Canyon',
    path: 'https://media.pointandplace.com/player/images/environment/Runyon_Canyon_A_2k_cube_specular.env',
  },
  {
    name: 'Cloudy Sky',
    path: 'https://media.pointandplace.com/player/images/environment/cloudy.env',
  },
  {
    name: 'City Night',
    path: 'https://media.pointandplace.com/player/images/environment/night.env',
  },
  {
    name: 'Warm Sky',
    path: 'https://media.pointandplace.com/player/images/environment/room.env',
  },
  {
    name: 'Mountain',
    path: 'https://media.pointandplace.com/player/images/environment/mountain.env',
  },
];

export default envmaps;
