import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';

import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
  ListItemSecondaryAction,
} from '@material-ui/core';

import RootRef from '@material-ui/core/RootRef';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { DragHandle, DeleteForever } from '@material-ui/icons';

import { withStyles } from '@material-ui/core/styles';

import colors from '../libs/colors';
import { getConfigHotspots } from '../redux/selectors';
import { setHotspots, setHotspot } from '../redux/actions';
import Label from './Label';
import GradientButton from './GradientButton';
import HotspotEdit from './HotspotEdit';

const styles = {
  root: {
    marginTop: '0.15rem',
    marginBottom: '0.15rem',
  },
  buttonContainer: {
    display: 'flex',
  },
  input: {
    width: '100%',
    backgroundColor: colors.inputBackground,
    border: 'none',
    borderRadius: 3,
    fontFamily: 'Montserrat',
    color: colors.contrastText,
    lineHeight: '2rem',
    height: '2rem',
  },
  textarea: {
    width: '100%',
    minWidth: '100%',
    maxWidth: '100%',
    backgroundColor: colors.inputBackground,
    border: 'none',
    borderRadius: 3,
    fontFamily: 'Montserrat',
    color: colors.contrastText,
    height: '8rem',
    minHeight: '8rem',
  },
  select: {
    width: '100%',
    padding: '0.25rem 0.5rem',
    margin: '0.25rem 0',
    border: 'none',
    fontWeight: 600,
    fontSize: 14,
    backgroundColor: colors.selectBackground,
    color: colors.contrastText,
    '& .MuiSelect-icon': {
      color: colors.contrastText,
    },
  },
  group: {
    margin: '0.5rem 0',
  },
  delete: {
    backgroundColor: colors.red,
    color: colors.contrastText,
    marginLeft: '0.25rem',
    minWidth: 28,
  },
  listItem: {
    alignItems: 'center',
    paddingLeft: '0.75rem',
    paddingRight: '0.25rem',
    backgroundColor: colors.inputBackground,
    borderRadius: 2,
    margin: '0.25rem 0',
  },
  listItemIcon: {
    color: colors.contrastText,
    minWidth: 36,
  },
  listItemText: {
    fontSize: 14,
    color: colors.contrastText,
  },
  white: {
    color: colors.contrastText,
  },
  chosedIcon: {
    fontSize: 14,
    color: colors.contrastText,
    display: 'flex',
    alignItems: 'center',
  },
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  ...draggableStyle,

  ...(isDragging && {
    opacity: 0.5,
  }),
});

class HotspotManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: 'list',
    };
    this.onDragEnd = this.onDragEnd.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  onDragEnd(result) {
    // If dropped outside
    if (!result.destination) {
      return;
    }

    const { props } = this;

    const items = reorder(
      props.hotspots,
      result.source.index,
      result.destination.index,
    );

    props.setHotspots(items);
  }

  renderList() {
    const { classes, hotspots } = this.props;
    const emptyMessage = <p className={classes.white}>Click the &quot;Add new hotspot&quot; button to start adding your first hotspot</p>;

    return (
      <>
        <Label label="Current Hotspots" />
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provider) => (
              <RootRef rootRef={provider.innerRef}>
                <List>
                  {
                    hotspots.length === 0 ? emptyMessage : hotspots.map((hotspot, index) => (
                      <Draggable key={hotspot.uuid} draggableId={hotspot.uuid} index={index}>
                        {(provided, snapshot) => (
                          <ListItem
                            className={classes.listItem}
                            ContainerComponent="li"
                            ContainerProps={{ ref: provided.innerRef }}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...provided.draggableProps}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style,
                            )}
                            disableGutters
                            dense
                          >
                            <ListItemIcon className={classes.listItemIcon}>
                              <DragHandle />
                            </ListItemIcon>
                            <ListItemText className={classes.listItemText} primary={hotspot.hotspotText} />
                            <Button
                              variant="contained"
                              color="secondary"
                              size="small"
                              onClick={() => {
                                this.setState({
                                  view: 'edit',
                                  hotspot,
                                });
                              }}
                            >
                              Edit
                            </Button>
                            <Button
                              variant="contained"
                              size="small"
                              className={classes.delete}
                              onClick={() => {
                                const { props } = this;

                                hotspots.splice(index, 1);
                                props.setHotspots(hotspots);
                              }}
                            >
                              <DeleteForever />
                            </Button>
                            <ListItemSecondaryAction />
                          </ListItem>
                        )}
                      </Draggable>
                    ))
                  }
                  {provider.placeholder}
                </List>
              </RootRef>
            )}
          </Droppable>
        </DragDropContext>
        <GradientButton
          margin="none"
          color="blue"
          onClick={() => {
            this.setState({
              view: 'edit',
            });
          }}
        >
          Add new hotspot
        </GradientButton>
      </>
    );
  }

  handleSave(hot) {
    const { props } = this;
    props.setHotspot(hot);

    this.setState({
      view: 'list',
      hotspot: null,
    });
  }

  handleCancel() {
    this.setState({
      view: 'list',
      hotspot: null,
    });
  }

  render() {
    const { view, hotspot } = this.state;

    return (
      <>
        {view === 'list' ? this.renderList() : null}
        {view === 'edit'
          ? (
            <HotspotEdit
              onSave={this.handleSave}
              onCancel={this.handleCancel}
              hotspot={hotspot}
            />
          ) : null}
      </>
    );
  }
}

export default connect(
  (state) => ({ hotspots: getConfigHotspots(state) }),
  {
    setHotspots,
    setHotspot,
  },
)(withStyles(styles)(withSnackbar(HotspotManager)));
