import React from 'react';
import { connect } from 'react-redux';
import {
  Drawer,
  Toolbar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import SidebarInitial from './SidebarInitial';
import SidebarSliders from './SidebarSliders';
import colors from '../libs/colors';
import sizes from '../libs/sizes';

const useStyles = makeStyles(() => ({
  drawer: {
    position: 'absolute',
    zIndex: 900,
    width: sizes.drawerWidth,
    flexShrink: 0,
    borderLeft: 'none',
    height: '100%',
    right: 0,
  },
  drawerPaper: {
    width: sizes.drawerWidth,
    backgroundColor: colors.drawerBackground,
    position: 'absolute',
  },
}));

function Sidebar(props) {
  const classes = useStyles();

  const { ui } = props;

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="right"
    >
      <Toolbar />
      {ui.sidebar === 'sliders' ? <SidebarSliders /> : <SidebarInitial />}
    </Drawer>
  );
}

export default connect(
  (state) => ({ ui: state.ui }),
  {},
)(Sidebar);
