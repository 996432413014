import React from 'react';

import { Tooltip, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Help } from '@material-ui/icons';

import colors from '../libs/colors';

const useStyles = makeStyles(() => ({
  label: {
    fontSize: 14,
    color: colors.contrastText,
  },
  icon: {
    fontSize: 16,
    marginLeft: 10,
    verticalAlign: 'middle',

    '&:hover': {
      cursor: 'help',
    },
  },
}));

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: colors.contrastText,
    color: colors.deepBlue,
    maxWidth: 300,
    fontSize: 14,
    boxShadow: theme.shadows[5],
  },
  arrow: {
    color: colors.contrastText,
  },
}))(Tooltip);

export default function Label(props) {
  const { id, label, help } = props;
  const classes = useStyles();

  const renderHelp = () => {
    if (!help) {
      return null;
    }

    return (
      <CustomTooltip placement="top" title={help} arrow>
        <Help className={classes.icon} />
      </CustomTooltip>
    );
  };

  return (
    <Typography id={id} color="primary">
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className={classes.label}>
        <strong>{label}</strong>
        { renderHelp() }
      </label>
    </Typography>
  );
}
