import React from 'react';
import ReactDOM from 'react-dom';

import EkyCalibrationTool from './EkyCalibrationTool';
import * as serviceWorker from './serviceWorker';

const rootElement = document.getElementById('root');
ReactDOM.render(
  <EkyCalibrationTool
    arid="700300019"
    checksum="jKI23eHqj1PQy7Cz2dkIK7h3Up8fXdHIKe4QvfSovR9ZozrDmIpVdQGqqttAGQ9nG69bW/dwEor3QGlqqb06fQ=="
    project="ZZZ0001 Test"
    product="Product Test"
    onSaveSuccess={() => {
      console.log('Saved settings!');
    }}
    onSaveError={(error) => {
      console.log('Save error!', error);
    }}
    onReport={(message) => {
      console.log('Sending report message', message);
    }}
    onClose={() => {
      console.log('Exiting calibration tool');
    }}
  />,
  rootElement,
);

// If you want your EkyCalibrationTool to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
