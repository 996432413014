import React from 'react';
import { connect } from 'react-redux';
import { styled } from '@material-ui/core/styles';
import * as BABYLON from 'babylonjs';
import 'babylonjs-loaders';
import fetch from 'isomorphic-fetch';
import { Fade, LinearProgress } from '@material-ui/core';

import { httpCodeCheck } from '../libs/http';
import { diff } from '../libs/diff';

import { setConfig, setModalMessage, openModal } from '../redux/actions';
import { getConfig, getConfigHotspots } from '../redux/selectors';

import envmaps from '../libs/envmaps';
import sizes from '../libs/sizes';

const CF_URL = 'https://d15mv1adrb1s6e.cloudfront.net';
const CF_STAGING_URL = 'https://d3swwaxifktov.cloudfront.net';

const PlayerCanvas = styled('canvas')({
  backgroundColor: 'rgba(0, 0, 0, 0.2)',
  position: 'absolute',
  zIndex: 1,
  bottom: 0,
  left: 0,
  width: `calc(100% - ${sizes.drawerWidth}px)`,
  height: `calc(100% - ${sizes.headbarHeight}px)`,
});

const LoadingContainer = styled('div')({
  backgroundColor: 'white',
  position: 'absolute',
  zIndex: 1001,
  bottom: 0,
  left: 0,
  width: `calc(100% - ${sizes.drawerWidth}px)`,
  height: `calc(100% - ${sizes.headbarHeight}px)`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const HotspotContainer = styled('div')({
  position: 'absolute',
  zIndex: 999,
  top: '50%',
  left: 10,
  transform: 'translateY(-50%)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

class Player extends React.Component {
  constructor() {
    super();
    this.canvas = React.createRef();

    // To build array of meshes
    this.modelMeshes = [];
    this.progress = 0;

    // Arrow Spheres
    this.diameterMultiplier = 1.25;

    // Keys
    this.hotspotAlphaKeys = [];
    this.hotspotBetaKeys = [];
    this.hotspotRadiusKeys = [];
  }

  loadProgress(event) {
    this.progress = ((event.loaded * 100) / event.total).toFixed();
  }

  setupArrowOverlays() {
    const { scene } = this;

    const newSize = new BABYLON.Vector3(this.diameterMultiplier, this.diameterMultiplier, this.diameterMultiplier);
    const arrowTex = new BABYLON.Texture(`${process.env.PUBLIC_URL}/images/arrow512.png`, scene);
    this.arrowXSphere = BABYLON.MeshBuilder.CreateSphere('_arrowXSphere', { diameter: this.biggestSize }, scene);
    this.arrowYSphere = BABYLON.MeshBuilder.CreateSphere('_arrowYSphere', { diameter: this.biggestSize }, scene);

    const arrowMaterialX = new BABYLON.StandardMaterial('arrowMaterialX', scene);
    arrowMaterialX.emissiveColor = new BABYLON.Color4(0.1647, 0.67059, 0.8863, 1);
    arrowMaterialX.opacityTexture = arrowTex;
    arrowMaterialX.backFaceCulling = false;
    arrowMaterialX.disableLighting = true;

    const arrowMaterialY = new BABYLON.StandardMaterial('arrowMaterialY', scene);
    arrowMaterialY.emissiveColor = new BABYLON.Color4(0.1647, 0.67059, 0.8863, 1);
    arrowMaterialY.opacityTexture = arrowTex;
    arrowMaterialY.backFaceCulling = false;
    arrowMaterialY.disableLighting = true;

    this.arrowXSphere.material = arrowMaterialX;
    this.arrowYSphere.material = arrowMaterialY;
    this.arrowXSphere.position.y = this.ySize / 2;
    this.arrowYSphere.position.y = this.ySize / 2;
    this.arrowXSphere.rotation.z = -Math.PI / 2;
    this.arrowXSphere.scaling = newSize;
    this.arrowYSphere.scaling = newSize;

    const arrowXKeys = [];
    arrowXKeys.push({
      frame: 0,
      value: -1.59,
    });
    arrowXKeys.push({
      frame: 60,
      value: 1.59,
    });
    const arrowYKeys = [];
    arrowYKeys.push({
      frame: 0,
      value: 0,
    });
    arrowYKeys.push({
      frame: 120,
      value: 2 * Math.PI,
    });
    const arrowXMatKeys = [];
    arrowXMatKeys.push({
      frame: 0,
      value: 0,
    });
    arrowXMatKeys.push({
      frame: 30,
      value: 1,
    });
    arrowXMatKeys.push({
      frame: 60,
      value: 0,
    });
    const arrowYMatKeys = [];
    arrowYMatKeys.push({
      frame: 0,
      value: 0,
    });
    arrowYMatKeys.push({
      frame: 30,
      value: 1,
    });
    arrowYMatKeys.push({
      frame: 90,
      value: 1,
    });
    arrowYMatKeys.push({
      frame: 120,
      value: 0,
    });
    const arrowXAnimation = new BABYLON.Animation('arrowXAnimation', 'rotation.x', 60, BABYLON.Animation.ANIMATIONTYPE_FLOAT, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
    arrowXAnimation.setKeys(arrowXKeys);
    this.arrowXSphere.animations.push(arrowXAnimation);
    const arrowYAnimation = new BABYLON.Animation('arrowYAnimation', 'rotation.y', 60, BABYLON.Animation.ANIMATIONTYPE_FLOAT, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
    arrowYAnimation.setKeys(arrowYKeys);
    this.arrowYSphere.animations.push(arrowYAnimation);
    const arrowXMatAnimation = new BABYLON.Animation('arrowXMatAnimation', 'material.alpha', 60, BABYLON.Animation.ANIMATIONTYPE_FLOAT, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
    arrowXMatAnimation.setKeys(arrowXMatKeys);
    const arrowYMatAnimation = new BABYLON.Animation('arrowYMatAnimation', 'material.alpha', 60, BABYLON.Animation.ANIMATIONTYPE_FLOAT, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
    arrowYMatAnimation.setKeys(arrowYMatKeys);
    this.arrowXSphere.animations.push(arrowXMatAnimation);
    this.arrowYSphere.animations.push(arrowYMatAnimation);
    this.arrowXSphere.material.alpha = 0;
    this.arrowYSphere.material.alpha = 0;
  }

  setArrowInterval() {
    const { scene } = this;

    this.arrowInterval = setInterval(() => {
      scene.beginAnimation(this.arrowXSphere, 0, 60, false);
      this.arrow2TimeOut = setTimeout(() => {
        scene.beginAnimation(this.arrowYSphere, 0, 120, false);
      }, 500);
    }, 8000);
  }

  setupAnimationKeys() {
    this.hotspotAlphaKeys.push({
      frame: 0,
      value: 0,
    });
    this.hotspotAlphaKeys.push({
      frame: 60,
      value: 2 * Math.PI,
    });
    this.hotspotAlphaAnimation = new BABYLON.Animation(
      '_hotspotAlphaAnimation',
      'alpha',
      60,
      BABYLON.Animation.ANIMATIONTYPE_FLOAT,
      BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE,
    );

    this.hotspotAlphaAnimation.setKeys(this.hotspotAlphaKeys);

    this.hotspotBetaKeys.push({
      frame: 0,
      value: 0,
    });
    this.hotspotBetaKeys.push({
      frame: 60,
      value: 2 * Math.PI,
    });
    this.hotspotBetaAnimation = new BABYLON.Animation(
      '_hotspotBetaAnimation',
      'beta',
      60,
      BABYLON.Animation.ANIMATIONTYPE_FLOAT,
      BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE,
    );
    this.hotspotBetaAnimation.setKeys(this.hotspotBetaKeys);

    this.hotspotRadiusKeys.push({
      frame: 0,
      value: 0,
    });
    this.hotspotRadiusKeys.push({
      frame: 60,
      value: 2 * Math.PI,
    });
    this.hotspotRadiusAnimation = new BABYLON.Animation(
      '_hotspotRadiusAnimation',
      'radius',
      60,
      BABYLON.Animation.ANIMATIONTYPE_FLOAT,
      BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE,
    );
    this.hotspotRadiusAnimation.setKeys(this.hotspotRadiusKeys);
  }

  getJsonConfig() {
    const { arid } = this.props;

    const configs = {
      // Get the uncached static config file in case we need to refresh
      model: `https://eyekandyc3cfd68aef9e4b40a2f48f5b475cbabd.s3.amazonaws.com/ekydemo2017_demo/product_settings/JSON/${arid}.json`,
      default: `${CF_URL}/product_settings/JSON/default-preset.json`,
    };

    // Original URL
    let configUrl = configs.default;
    const code = httpCodeCheck(configs.model);
    const { props } = this;

    if (code >= 200 && code <= 203) {
      configUrl = configs.model;
    }

    fetch(configUrl)
      .then((res) => res.json())
      .then((data) => {
        props.setConfig(data);

        if (configUrl === configs.default) {
          this.applyJsonConfig(data, false, false, true);
        } else {
          this.applyJsonConfig(data);
        }
        return data;
      })
      .then((data) => {
        this.firstConfigApplied = true;
        window.originalConfig = JSON.parse(JSON.stringify(data));
      });
  }

  applyJsonConfig(data, updateGround, isPreset, isDefault) {
    this.orbitOrNot = true;

    this.light01.direction.x = data.lights.directional.light01.direction.x;
    this.light01.direction.y = data.lights.directional.light01.direction.y;
    this.light01.direction.z = data.lights.directional.light01.direction.z;
    this.light02.direction.x = data.lights.directional.light02.direction.x;
    this.light02.direction.y = data.lights.directional.light02.direction.y;
    this.light02.direction.z = data.lights.directional.light02.direction.z;
    this.light03.direction.x = data.lights.directional.light03.direction.x;
    this.light03.direction.y = data.lights.directional.light03.direction.y;
    this.light03.direction.z = data.lights.directional.light03.direction.z;
    this.light01.intensity = data.lights.directional.light01.intensity;
    this.light02.intensity = data.lights.directional.light02.intensity;
    this.light03.intensity = data.lights.directional.light03.intensity;
    this.ambLight.intensity = data.lights.ambient.intensity;
    this.ambLight.direction.x = data.lights.ambient.direction.x;
    this.ambLight.direction.y = data.lights.ambient.direction.y;
    this.ambLight.direction.z = data.lights.ambient.direction.z;
    if (data.lights.ambient.color !== undefined) {
      const rgb = data.lights.ambient.color;
      this.ambLight.diffuse = new BABYLON.Color3(rgb.r, rgb.g, rgb.b);
    }
    if (data.lights.directional.light01.color !== undefined && data.lights.directional.light01.color.r !== undefined) {
      const rgb = data.lights.directional.light01.color;
      this.light01.diffuse = new BABYLON.Color3(rgb.r, rgb.g, rgb.b);
    }
    if (data.lights.directional.light02.color !== undefined && data.lights.directional.light02.color.r !== undefined) {
      const rgb = data.lights.directional.light02.color;
      this.light02.diffuse = new BABYLON.Color3(rgb.r, rgb.g, rgb.b);
    }
    if (data.lights.directional.light03.color !== undefined && data.lights.directional.light03.color.r !== undefined) {
      const rgb = data.lights.directional.light03.color;
      this.light03.diffuse = new BABYLON.Color3(rgb.r, rgb.g, rgb.b);
    }
    if (data.environment.orbitCamera !== undefined && data.environment.orbitCamera === false) {
      this.orbitOrNot = false;
    }
    if (data.camera.panPos !== undefined && data.camera.panPos.x !== undefined) {
      const panP = data.camera.panPos;
      this.camera.setPosition(new BABYLON.Vector3(panP.x, panP.y, panP.z));
    }
    if (data.environment.allowPanning !== undefined && data.environment.allowPanning) {
      this.camera.panningAxis = new BABYLON.Vector3(1, 1, 1);
    }
    if (data.environment.allowZoomin !== undefined && data.environment.allowZoomin) {
      this.camera.lowerRadiusLimit = 0.0;
    }
    if (data.camera.targetPos !== undefined && data.camera.targetPos.x !== undefined) {
      const tarP = data.camera.targetPos;
      this.camera.target.x = tarP.x;
      this.camera.target.y = tarP.y;
      this.camera.target.z = tarP.z;
    }
    if (!isDefault && !isPreset) {
      this.camera.alpha = data.camera.startPos.alpha;
      this.camera.beta = data.camera.startPos.beta;
      this.camera.radius = data.camera.startPos.radius;
      const newSize = new BABYLON.Vector3(data.environment.arrowSize, data.environment.arrowSize, data.environment.arrowSize);
      this.arrowXSphere.scaling = newSize;
      this.arrowYSphere.scaling = newSize;
    }
    this.groundMaterial.shadowLevel = 1 - data.shadows.darkness;
    if (updateGround) {
      this.groundPlane.position.y = data.environment.groundY;
    }
    this.shadowGenerator01.blurKernel = data.shadows.blur;
    this.shadowGenerator02.blurKernel = data.shadows.blur;
    this.shadowGenerator03.blurKernel = data.shadows.blur;
    let skybox;
    if (envmaps[data.environment.map].path.indexOf('.env') > -1) {
      skybox = BABYLON.CubeTexture.CreateFromPrefilteredData(envmaps[data.environment.map].path, this.scene);
    } else if (envmaps[data.environment.map].path.indexOf('.hdr') > -1) {
      skybox = new BABYLON.HDRCubeTexture(envmaps[data.environment.map].path, this.scene);
    } else {
      skybox = new BABYLON.CubeTexture(envmaps[data.environment.map].path, this.scene);
    }

    this.scene.environmentTexture = skybox;
    this.scene.environmentTexture.level = data.environment.amount;
    if (!data.environment.seeUnder) {
      this.camera.upperBetaLimit = 1.6;
      if (this.camera.beta > 1.6) {
        this.camera.beta = 1.6;
      }
    }
    // this.defaultPipeline.bloomEnabled=data.post.bloom.enabled;
    if (data.post.bloom.weight > 0) {
      this.defaultPipeline.bloomEnabled = true;
      this.defaultPipeline.bloomWeight = data.post.bloom.weight;
    }
    if (data.post.image !== undefined) {
      if (data.post.image.contrast !== 1.0 || data.post.image.exposure !== 1.0) {
        // const postProcess = new BABYLON.ImageProcessingPostProcess("processing", 1.0, this.camera);
        // postProcess.exposure = data.post.image.exposure;
        // postProcess.contrast = data.post.image.contrast;

        // this.defaultPipeline.imageProcessingEnabled = true;
        this.defaultPipeline.imageProcessing.contrast = data.post.image.contrast;
        this.defaultPipeline.imageProcessing.exposure = data.post.image.exposure;
      }

      // this.defaultPipeline.imageProcessing.colorCurvesEnabled = true; // false by default
      // if (this.defaultPipeline.imageProcessing.colorCurvesEnabled) {
      //   this.defaultPipeline.imageProcessing.colorCurves = new BABYLON.ColorCurves();

      //   this.defaultPipeline.imageProcessing.colorCurves.globalDensity = 0;
      //   this.defaultPipeline.imageProcessing.colorCurves.globalExposure = data.post.image.brightness || 0;
      //   this.defaultPipeline.imageProcessing.colorCurves.globalHue = 30;
      //   this.defaultPipeline.imageProcessing.colorCurves.globalSaturation = data.post.image.saturation || 0;
      //   this.defaultPipeline.imageProcessing.colorCurves.highlightsDensity = 0;
      //   this.defaultPipeline.imageProcessing.colorCurves.highlightsExposure = data.post.image.brightness || 0;
      //   this.defaultPipeline.imageProcessing.colorCurves.highlightsHue = 30;
      //   this.defaultPipeline.imageProcessing.colorCurves.highlightsSaturation = data.post.image.saturation || 0;
      //   this.defaultPipeline.imageProcessing.colorCurves.midtonesDensity = 0;
      //   this.defaultPipeline.imageProcessing.colorCurves.midtonesExposure = data.post.image.brightness || 0;
      //   this.defaultPipeline.imageProcessing.colorCurves.midtonesHue = 30;
      //   this.defaultPipeline.imageProcessing.colorCurves.midtonesSaturation = data.post.image.saturation || 0;
      //   this.defaultPipeline.imageProcessing.colorCurves.shadowsDensity = 0;
      //   this.defaultPipeline.imageProcessing.colorCurves.shadowsExposure = data.post.image.brightness || 0;
      //   this.defaultPipeline.imageProcessing.colorCurves.shadowsHue = 30;
      //   this.defaultPipeline.imageProcessing.colorCurves.shadowsDensity = 0;
      //   this.defaultPipeline.imageProcessing.colorCurves.shadowsSaturation = data.post.image.saturation || 0;
      // }
    }

    this.ssao.fallOff = data.post.ao.falloff;
    this.ssao.area = data.post.ao.area;
    this.ssao.radius = data.post.ao.radius;
    this.ssao.totalStrength = data.post.ao.strength;
    this.ssao.base = data.post.ao.base;

    this.setupAnimationKeys();
  }

  applySingleChange(key) {
    const { config } = this.props;

    switch (key) {
      // Post
      case 'post.image.contrast':
        this.defaultPipeline.imageProcessing.contrast = config.post.image.contrast;
        break;
      case 'post.image.exposure':
        this.defaultPipeline.imageProcessing.exposure = config.post.image.exposure;
        break;

      // Lights
      case 'lights.directional.light01.direction.x':
        this.light01.direction.x = config.lights.directional.light01.direction.x;
        break;
      case 'lights.directional.light01.direction.y':
        this.light01.direction.y = config.lights.directional.light01.direction.y;
        break;
      case 'lights.directional.light01.direction.z':
        this.light01.direction.z = config.lights.directional.light01.direction.z;
        break;
      case 'lights.directional.light02.direction.x':
        this.light02.direction.x = config.lights.directional.light02.direction.x;
        break;
      case 'lights.directional.light02.direction.y':
        this.light02.direction.y = config.lights.directional.light02.direction.y;
        break;
      case 'lights.directional.light02.direction.z':
        this.light02.direction.z = config.lights.directional.light02.direction.z;
        break;
      case 'lights.directional.light03.direction.x':
        this.light03.direction.x = config.lights.directional.light03.direction.x;
        break;
      case 'lights.directional.light03.direction.y':
        this.light03.direction.y = config.lights.directional.light03.direction.y;
        break;
      case 'lights.directional.light03.direction.z':
        this.light03.direction.z = config.lights.directional.light03.direction.z;
        break;
      case 'lights.directional.light01.intensity':
        this.light01.intensity = config.lights.directional.light01.intensity;
        break;
      case 'lights.directional.light02.intensity':
        this.light02.intensity = config.lights.directional.light02.intensity;
        break;
      case 'lights.directional.light03.intensity':
        this.light03.intensity = config.lights.directional.light03.intensity;
        break;
      case 'lights.directional.light01.color.r': {
        const { r, g, b } = config.lights.directional.light01.color;
        this.light01.diffuse = new BABYLON.Color3(r, g, b);
        break;
      }
      case 'lights.directional.light02.color.r': {
        const { r, g, b } = config.lights.directional.light02.color;
        this.light02.diffuse = new BABYLON.Color3(r, g, b);
        break;
      }
      case 'lights.directional.light03.color.r': {
        const { r, g, b } = config.lights.directional.light03.color;
        this.light03.diffuse = new BABYLON.Color3(r, g, b);
        break;
      }
      case 'lights.ambient.intensity':
        this.ambLight.intensity = config.lights.ambient.intensity;
        break;
      case 'lights.ambient.direction.x':
        this.ambLight.direction.x = config.lights.ambient.direction.x;
        break;
      case 'lights.ambient.direction.y':
        this.ambLight.direction.y = config.lights.ambient.direction.y;
        break;
      case 'lights.ambient.direction.z':
        this.ambLight.direction.z = config.lights.ambient.direction.z;
        break;
      case 'lights.ambient.color.r': {
        const { r, g, b } = config.lights.ambient.color;
        this.ambLight.diffuse = new BABYLON.Color3(r, g, b);
        break;
      }

      // Shadows
      case 'shadows.darkness':
        this.groundMaterial.shadowLevel = 1 - config.shadows.darkness;
        break;
      case 'shadows.blur':
        this.shadowGenerator01.blurKernel = config.shadows.blur;
        this.shadowGenerator02.blurKernel = config.shadows.blur;
        this.shadowGenerator03.blurKernel = config.shadows.blur;
        break;

      // Envmap
      case 'environment.map': {
        let skybox;
        if (envmaps[config.environment.map].path.indexOf('.env') > -1) {
          skybox = BABYLON.CubeTexture.CreateFromPrefilteredData(envmaps[config.environment.map].path, this.scene);
        } else if (envmaps[config.environment.map].path.indexOf('.hdr') > -1) {
          skybox = new BABYLON.HDRCubeTexture(envmaps[config.environment.map].path, this.scene);
        } else {
          skybox = new BABYLON.CubeTexture(envmaps[config.environment.map].path, this.scene);
        }
        this.scene.environmentTexture = skybox;
        break;
      }
      case 'environment.amount':
        if (this.scene.environmentTexture) {
          this.scene.environmentTexture.level = config.environment.amount;
        }
        break;
      case 'environment.allowPanning':
        if (config.environment.allowPanning) {
          this.camera.panningAxis = new BABYLON.Vector3(1, 1, 1);
        } else {
          this.camera.panningAxis = new BABYLON.Vector3(0, 0, 0);
        }
        break;
      case 'environment.seeUnder':
        if (!config.environment.seeUnder) {
          this.camera.upperBetaLimit = 1.6;
          if (this.camera.beta > 1.6) {
            this.camera.beta = 1.6;
          }
        } else {
          this.camera.upperBetaLimit = Math.PI;
        }
        break;
      case 'post.image.brightness':
        this.defaultPipeline.imageProcessing.colorCurves.globalExposure = config.post.image.brightness;
        this.defaultPipeline.imageProcessing.colorCurves.highlightsExposure = config.post.image.brightness;
        this.defaultPipeline.imageProcessing.colorCurves.midtonesExposure = config.post.image.brightness;
        this.defaultPipeline.imageProcessing.colorCurves.shadowsExposure = config.post.image.brightness;
        break;
      case 'post.image.saturation':
        this.defaultPipeline.imageProcessing.colorCurves.globalSaturation = config.post.image.saturation;
        this.defaultPipeline.imageProcessing.colorCurves.highlightsSaturation = config.post.image.saturation;
        this.defaultPipeline.imageProcessing.colorCurves.midtonesSaturation = config.post.image.saturation;
        this.defaultPipeline.imageProcessing.colorCurves.shadowsSaturation = config.post.image.saturation;
        break;
      default:
        break;
    }
  }

  moveCameraToHotspot(index) {
    const { hotspots } = this.props;

    this.hotspotAlphaKeys[0].value = this.scene.activeCamera.alpha % (2 * Math.PI);
    this.hotspotBetaKeys[0].value = this.scene.activeCamera.beta % (2 * Math.PI);
    this.hotspotRadiusKeys[0].value = this.scene.activeCamera.radius;
    this.hotspotAlphaKeys[1].value = (hotspots[index].alpha % (2 * Math.PI));
    this.hotspotBetaKeys[1].value = (hotspots[index].beta % (2 * Math.PI));
    this.hotspotRadiusKeys[1].value = hotspots[index].radius;
    this.hotspotAlphaAnimation.setKeys(this.hotspotAlphaKeys);
    this.hotspotBetaAnimation.setKeys(this.hotspotBetaKeys);
    this.hotspotRadiusAnimation.setKeys(this.hotspotRadiusKeys);
    this.scene.activeCamera.detachControl(this.canvas);
    this.scene.activeCamera.animations = [];
    this.scene.activeCamera.animations.push(this.hotspotAlphaAnimation);
    this.scene.activeCamera.animations.push(this.hotspotBetaAnimation);
    this.scene.activeCamera.animations.push(this.hotspotRadiusAnimation);
    this.cameraAnimation = this.scene.beginAnimation(this.scene.activeCamera, 0, 60, false);
  }

  componentDidMount() {
    const { props } = this;

    // Get the canvas DOM element
    const canvas = this.canvas.current;
    // Load the 3D engine
    this.engine = new BABYLON.Engine(canvas, true, { premultipliedAlpha: false, preserveDrawingBuffer: true }, true);
    this.engine.hideLoadingUI();
    this.engine.loadingUIBackgroundColor = 'white';
    canvas.addEventListener('contextmenu', (e) => { e.preventDefault(); }, false);
    // This is really important to tell Babylon.js to use decomposeLerp and matrix interpolation
    BABYLON.Animation.AllowMatricesInterpolation = true;
    BABYLON.SceneLoader.forcefullsceneloadingforincremental = true;
    BABYLON.SceneLoader.OnPluginActivatedObservable.add((plugin) => {
      this.currentPluginName = plugin.name;
      if (this.currentPluginName === 'gltf') {
        plugin.onValidatedObservable.add((results) => {
          if (results.issues.numErrors > 0) {
            canvas.style.opacity = 0;
            window.console.warn('GLTF Plugin Invalid: Cannot load GLTF.');
            props.setModalMessage('ERROR', `Cannot load GLTF, ${JSON.stringify(results)}`);
            props.openModal();
          }
        });
      }
    });

    // CreateScene function that creates and return the scene
    const createScene = () => {
      const { arid } = this.props;

      // Create a basic BJS Scene object
      this.scene = new BABYLON.Scene(this.engine);
      this.scene.clearColor = new BABYLON.Color4(1.0, 1.0, 1.0, 1.0);

      const urlLoadOrder = [
        `${CF_URL}/product_bundles/GLB/${arid}.glb`,
        `${CF_URL}/product_bundles/gltf2glb-output-compressed/${arid}.glb`,
        `${CF_URL}/product_bundles/GLTF_Compressed/${arid}/${arid}.gltf`,
        `${CF_URL}/product_bundles/GLTF/${arid}/${arid}.gltf`,
        `/files/${arid}.gltf`,
        `${CF_STAGING_URL}/GLB/${arid}.glb`,
      ];

      // Original URL
      let assetUrl = urlLoadOrder[3];
      let currAsset = 0;
      let code;
      do {
        code = httpCodeCheck(urlLoadOrder[currAsset]);
        if (code >= 200 && code <= 203) {
          assetUrl = urlLoadOrder[currAsset];
        }
        currAsset++;
      } while (code !== 200 && currAsset < urlLoadOrder.length);

      if (currAsset >= urlLoadOrder.length) {
        window.console.warn('Load array out of bounds, no models detected.');
        props.setModalMessage('ERROR', `Load error: ${arid} throws 404`);
        props.openModal();

        return null;
      }

      if (window.location.href.indexOf('env=staging') !== -1) {
        assetUrl = `${CF_STAGING_URL}/GLB/${arid}.glb`;
        console.log(`model loaded from: ${assetUrl}`);
      }

      const folderPath = BABYLON.Tools.GetFolderPath(assetUrl);
      const fileName = BABYLON.Tools.GetFilename(assetUrl);

      // Append glTF model to scene.
      BABYLON.SceneLoader.LoadAsync(folderPath, fileName, this.engine, this.loadProgress.bind(this)).then((gltf) => {
        if (this.scene) {
          this.scene.dispose();
        }

        this.engine.clearInternalTexturesCache();
        this.scene = gltf;

        this.sceneBounds = gltf.getWorldExtends();

        if (this.sceneBounds !== undefined) {
          this.maxYPos = this.sceneBounds.max.y;
          this.minYPos = this.sceneBounds.min.y;
          this.maxXPos = this.sceneBounds.max.x;
          this.minXPos = this.sceneBounds.min.x;
          this.maxZPos = this.sceneBounds.max.z;
          this.minZPos = this.sceneBounds.min.z;
        }
        this.xSize = this.maxXPos - this.minXPos;
        this.ySize = this.maxYPos - this.minYPos;
        this.zSize = this.maxZPos - this.minZPos;

        this.biggestSize = Math.max(Math.max(this.xSize, this.ySize), this.zSize);

        this.scene.createDefaultCamera(true, true, true);
        this.camera = this.scene.activeCamera;

        // Expose the camera
        window.camera = this.camera;
        window.defaultTarget = this.camera.getTarget();
        this.camera.storeState();

        if (this.currentPluginName === 'gltf') {
          // glTF assets use a +Z forward convention while the default camera faces +Z. Rotate the camera to look at the front of the asset.
          this.camera.alpha += Math.PI;
        }
        this.camera.useFramingBehavior = true;

        const framingBehavior = this.camera.getBehaviorByName('Framing');
        framingBehavior.framingTime = 0;
        framingBehavior.elevationReturnTime = -1;

        if (this.scene.meshes.length) {
          this.camera.lowerRadiusLimit = null;
          framingBehavior.zoomOnBoundingInfo(this.sceneBounds.min, this.sceneBounds.max);
        }
        const low = this.biggestSize / 1.5;
        this.camera.lowerRadiusLimit = low;
        this.camera.pinchPrecision = 200 / low;
        this.camera.upperRadiusLimit = 5 * low;

        this.camera.wheelDeltaPercentage = 0.01;
        this.camera.pinchDeltaPercentage = 0.01;
        this.camera.panningAxis = new BABYLON.Vector3(0, 0, 0);
        this.camera.beta = 1.2;

        // Create a built-in "ground" shape; its constructor takes 6 params : name, width, height, subdivision, scene, updatable
        this.groundMaterial = new BABYLON.BackgroundMaterial('_groundMaterial', this.scene);
        this.groundMaterial.diffuseColor = new BABYLON.Color3(0, 1, 1);
        this.groundMaterial.specularColor = new BABYLON.Color3(0, 1, 1);
        this.groundMaterial.shadowLevel = 0.5;
        this.groundMaterial.alphaLevel = 1;

        this.groundPlane = BABYLON.MeshBuilder.CreateGround('_ground', { width: 128, height: 128 }, this.scene);
        this.groundPlane.position = new BABYLON.Vector3(0, this.minYPos - 0.001, 0);
        this.groundPlane.material = this.groundMaterial;
        this.groundPlane.receiveShadows = true;

        for (let i = 0; i < this.scene.meshes.length; i++) {
          if (this.scene.meshes[i].name !== '_ground') {
            this.modelMeshes.push(this.scene.meshes[i]);
            this.modelMeshes.doNotSyncBoundingInfo = true;
          }
        }
        const showGizmo = true;
        const maxYLightPos = this.maxYPos + this.maxYPos / 5;
        const maxXLightPos = this.maxXPos + this.maxXPos / 5;
        const maxZLightPos = this.maxZPos + this.maxZPos / 5;
        const minZLightPos = this.minZPos - this.minZPos / 5;

        this.light01 = new BABYLON.DirectionalLight('_light01', new BABYLON.Vector3(0, -1, 0.3), this.scene);
        this.light01.position = new BABYLON.Vector3(0, maxYLightPos, minZLightPos);
        this.light01.diffuse = new BABYLON.Color3(1, 1, 1);
        this.light01.specular = new BABYLON.Color3(1, 1, 1);
        this.light01.intensity = 1.0;
        if (showGizmo) {
          const light01Gizmo = new BABYLON.LightGizmo();
          light01Gizmo.light = this.light01;
        }

        this.light02 = new BABYLON.DirectionalLight('_light02', new BABYLON.Vector3(0.8, -1, -0.3), this.scene);
        this.light02.position = new BABYLON.Vector3(-maxXLightPos, maxYLightPos, maxZLightPos);
        this.light02.diffuse = new BABYLON.Color3(1, 1, 1);
        this.light02.specular = new BABYLON.Color3(1, 1, 1);
        this.light02.intensity = 1.0;

        if (showGizmo) {
          const light02Gizmo = new BABYLON.LightGizmo();
          light02Gizmo.light = this.light02;
        }

        this.light03 = new BABYLON.DirectionalLight('_light03', new BABYLON.Vector3(-0.8, -1, -0.3), this.scene);
        this.light03.position = new BABYLON.Vector3(maxXLightPos, maxYLightPos, maxZLightPos);
        this.light03.diffuse = new BABYLON.Color3(1, 1, 1);
        this.light03.specular = new BABYLON.Color3(1, 1, 1);
        this.light03.intensity = 1.0;

        if (showGizmo) {
          const light03Gizmo = new BABYLON.LightGizmo();
          light03Gizmo.light = this.light03;
        }

        this.ambLight = new BABYLON.HemisphericLight('_ambLight', new BABYLON.Vector3(0, 0.5, -1), this.scene);
        this.ambLight.diffuse = new BABYLON.Color3(1, 1, 1);
        this.ambLight.specular = new BABYLON.Color3(1, 1, 1);
        this.ambLight.intensity = 0.5;
        if (showGizmo) {
          const ambLightGizmo = new BABYLON.LightGizmo();
          ambLightGizmo.light = this.ambLight;
        }

        this.shadowGenerator01 = new BABYLON.ShadowGenerator(128, this.light01);
        this.shadowGenerator01.useBlurExponentialShadowMap = true;
        this.shadowGenerator01.useKernelBlur = true;
        this.shadowGenerator01.blurKernel = 64;

        this.shadowGenerator02 = new BABYLON.ShadowGenerator(128, this.light02);
        this.shadowGenerator02.useBlurExponentialShadowMap = true;
        this.shadowGenerator02.useKernelBlur = true;
        this.shadowGenerator02.blurKernel = 64;

        this.shadowGenerator03 = new BABYLON.ShadowGenerator(128, this.light03);
        this.shadowGenerator03.useBlurExponentialShadowMap = true;
        this.shadowGenerator03.useKernelBlur = true;
        this.shadowGenerator03.blurKernel = 64;
        this.shadowGenerator01.getShadowMap().renderList = this.modelMeshes;
        this.shadowGenerator02.getShadowMap().renderList = this.modelMeshes;
        this.shadowGenerator03.getShadowMap().renderList = this.modelMeshes;

        this.defaultPipeline = new BABYLON.DefaultRenderingPipeline('default', true, this.scene, [this.camera]);
        if (this.engine.webGLVersion > 1) {
          this.defaultPipeline.samples = 2;
        } else {
          this.defaultPipeline.fxaaEnabled = false;
          this.defaultPipeline.samples = 1;
        }

        this.defaultPipeline.bloomEnabled = true;
        this.defaultPipeline.bloomWeight = 0.05;
        this.defaultPipeline.cameraFov = this.camera.fov;

        this.defaultPipeline.imageProcessingEnabled = true;
        this.defaultPipeline.imageProcessing.contrast = 1.0;
        this.defaultPipeline.imageProcessing.exposure = 1.0;
        const ssaoRatio = {
          ssaoRatio: 0.5, // Ratio of the SSAO post-process usually a lower resolution
          combineRatio: 1.0, // Ratio of the combine post-process (combines the SSAO and the scene)
        };
        this.ssao = new BABYLON.SSAORenderingPipeline('ssao', this.scene, ssaoRatio);
        this.ssao.fallOff = 0.000001;
        this.ssao.area = 0.01;
        this.ssao.radius = 0.00005;
        this.ssao.totalStrength = 1.35;
        this.ssao.base = 1;
        this.scene.postProcessRenderPipelineManager.attachCamerasToRenderPipeline('ssao', this.scene.activeCamera);

        this.scene.clearColor = new BABYLON.Color4(1.0, 1.0, 1.0, 1.0);
        this.scene.autoClear = false;
        this.scene.autoClearDepthAndStencil = false;

        this.setupArrowOverlays();
        this.setArrowInterval();

        this.getJsonConfig();
      });

      this.scene.whenReadyAsync().then(() => {
        this.sceneBooted = true;
        // run the render loop
        this.engine.runRenderLoop(() => {
          if (document.hidden) {
            return;
          }

          if (this.camera) {
            this.scene.render();
          }
        });
      });
      return this.scene;
    };

    // call the createScene function
    createScene();

    // the canvas/window resize event handler
    window.addEventListener('resize', () => {
      this.engine.resize();
    });
  }

  componentDidUpdate(prevProps) {
    const { config } = this.props;
    const { config: confPrev } = prevProps;

    const flatConfig = JSON.stringify(config);
    const flatCurrentConfig = JSON.stringify(confPrev);

    if (flatConfig !== flatCurrentConfig
      && flatConfig !== '{}'
      && this.firstConfigApplied === true) {
      const differences = diff(config, confPrev);
      for (let index = 0; index < differences.length; index++) {
        const key = differences[index];
        this.applySingleChange(key);
      }
    }
  }

  // Prevent memory leaks while developing
  componentWillUnmount() {
    // Stop the render loop
    this.engine.runRenderLoop(null);

    // Clear any intervals and timeouts
    clearInterval(this.arrowInterval);
    clearTimeout(this.arrow2TimeOut);
    this.arrowInterval = null;
    this.arrow2TimeOut = null;

    // Fully shutdown BabylonJS
    this.engine.dispose();
  }

  render() {
    const { hotspots } = this.props;

    return (
      <>
        <PlayerCanvas
          ref={this.canvas}
          id="renderCanvas"
          touch-action="none"
        />
        <Fade in={!this.sceneBooted}>
          <LoadingContainer>
            <div>
              <img src={`${process.env.PUBLIC_URL}/images/eyekandy-logo-single.png`} alt="Calibration Tool is loading the files..." />
              <LinearProgress variant="determinate" value={Number(this.progress)} />
            </div>
          </LoadingContainer>
        </Fade>
        <HotspotContainer>
          {
            hotspots.map((hotspot, index) => (
              <div key={hotspot.uuid}>
                <button
                  title={hotspot.hotspotText}
                  type="button"
                  onClick={() => {
                    this.moveCameraToHotspot(index);
                  }}
                  className="icon icon-preview"
                >
                  <span className={`typcn icon-default ${hotspot.hotspotIcon}`} />
                </button>
              </div>
            ))
          }
        </HotspotContainer>
      </>
    );
  }
}

export default connect(
  (state) => ({ config: getConfig(state), hotspots: getConfigHotspots(state) }),
  {
    setConfig,
    setModalMessage,
    openModal,
  },
)(Player);
