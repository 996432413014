import { get } from 'lodash';
import uuid from 'react-uuid';

// Get full state
export const getState = (store) => store;

// Config selectors
export const getConfig = (store) => JSON.parse(JSON.stringify(store.config));
export const getConfigSingle = (store, mapping) => get(store.config, mapping, undefined);
export const getConfigMultiple = (store, mapping) => mapping.map((map) => get(store.config, map, 0));

export const getConfigHotspots = (store) => store.config.hotspots.map((item) => {
  if (!item.uuid) {
    const key = uuid();
    // eslint-disable-next-line no-param-reassign
    item.uuid = key;
  }

  return item;
});
