import { combineReducers } from 'redux';
import appReducers from './app';
import configReducers from './config';
import uiReducers from './ui';

export default combineReducers({
  app: appReducers,
  config: configReducers,
  ui: uiReducers,
});
