import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Button, Collapse } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  ArrowDropDown,
  ArrowRight,
} from '@material-ui/icons';
import colors from '../libs/colors';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '0.75rem',
    marginBottom: '0.75rem',
  },
  inner: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  label: {
    textAlign: 'left',
    width: '100%',
  },
}));

const EkyButton = withStyles({
  root: {
    width: '100%',
    textTransform: 'capitalize',
    justifyContent: 'space-between',
    fontWeight: 600,
    backgroundColor: colors.selectBackground,
    borderRadius: 0,
    boxShadow: 'none',
  },
})(Button);

function Collapsible(props) {
  const classes = useStyles();
  const {
    children,
    label,
    icon,
  } = props;

  const [isCollapsed, setCollapsed] = useState(false);

  return (
    <div className={classes.root}>
      <EkyButton
        variant="contained"
        color="secondary"
        size="large"
        startIcon={isCollapsed ? <ArrowDropDown /> : <ArrowRight /> }
        endIcon={icon}
        onClick={() => (setCollapsed(!isCollapsed))}
      >
        <span className={classes.label}>{label}</span>
      </EkyButton>
      <Collapse in={isCollapsed}>
        <div className={classes.inner}>
          {children || <p>Warning: childen component is missing in &lt;Collapsible&gt;.jsx</p>}
        </div>
      </Collapse>
    </div>
  );
}

export default connect(
  () => ({ store: {} }),
  {},
)(Collapsible);
