import React from 'react';
import { connect } from 'react-redux';
import io from 'socket.io-client';
import { withSnackbar } from 'notistack';

import { setModalMessage, clearSocket } from '../redux/actions';

class SocketManager extends React.Component {
  constructor() {
    super();
    this.canvas = React.createRef();

    this.disconnected = false;
    this.reconnectSnackbar = false;
  }

  componentDidMount() {
    const { props } = this;
    const { enqueueSnackbar, closeSnackbar } = props;

    this.socket = io('http://eyekandy-player-stage.eu-west-2.elasticbeanstalk.com/', {
      path: '/socket.io',
      transports: ['websocket'],
    });

    this.socket.on('connect', () => {
      if (this.disconnected) {
        enqueueSnackbar('Successfully reconnected to the calibration server', {
          variant: 'success',
        });

        this.disconnected = false;
        if (this.reconnectSnackbar) {
          closeSnackbar(this.reconnectSnackbar);
          this.reconnectSnackbar = false;
        }
      }
    });

    this.socket.on('disconnect', (reason) => {
      window.console.warn('Socket disconnected', reason);
      enqueueSnackbar('No connection to the calibration server, is your internet connection working?', {
        variant: 'error',
      });
      this.disconnected = true;
    });

    this.socket.on('reconnect_attempt', () => {
      if (!this.reconnectSnackbar) {
        this.reconnectSnackbar = enqueueSnackbar('Trying to reconnect to the server', {
          variant: 'warning',
          persist: true,
        });
      }
    });

    this.socket.on('successExport', () => {
      props.setModalMessage('SAVE_SUCCESSFUL');
      props.clearSocket();

      const { onSaveSuccess } = props;
      if (onSaveSuccess) {
        onSaveSuccess();
      }
    });

    this.socket.on('errorExport', (error) => {
      props.setModalMessage('ERROR', `Socket.io returned error, ${JSON.stringify(error)}`);
      props.clearSocket();

      const { onSaveError } = props;
      if (onSaveError) {
        onSaveError(error);
      }
    });
  }

  componentDidUpdate() {
    const { app } = this.props;
    const { event, data } = app.socket;

    if (event && data) {
      this.socket.emit(event, data);
    }
  }

  render() {
    return null;
  }
}

export default connect(
  (state) => ({ app: state.app }),
  {
    setModalMessage,
    clearSocket,
  },
)(withSnackbar(SocketManager));
