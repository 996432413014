import {
  OPEN_MODAL,
  CLOSE_MODAL,
  SET_MODAL_MESSAGE,
  SET_CONFIG,
  SET_CONFIG_SINGLE,
  SET_HOTSPOTS,
  SET_HOTSPOT,
  HELP_DISPLAYED,
  SET_HELP_STEP,
  SEND_SOCKET,
  CLEAR_SOCKET,
  SET_SIDEBAR,
} from './actionTypes';

export const openModal = () => ({
  type: OPEN_MODAL,
});

export const closeModal = () => ({
  type: CLOSE_MODAL,
});

export const setModalMessage = (message, debug) => ({
  type: SET_MODAL_MESSAGE,
  payload: {
    message,
    debug,
  },
});

export const setConfig = (config) => ({
  type: SET_CONFIG,
  payload: {
    ...config,
  },
});

export const setConfigSingle = (mapping, value) => ({
  type: SET_CONFIG_SINGLE,
  payload: {
    mapping,
    value,
  },
});

export const setHotspots = (hotspots) => ({
  type: SET_HOTSPOTS,
  payload: {
    hotspots,
  },
});


export const setHotspot = (hotspot) => ({
  type: SET_HOTSPOT,
  payload: {
    hotspot,
  },
});


export const setHelpDisplayed = () => ({
  type: HELP_DISPLAYED,
});

export const sendSocket = (event, data) => ({
  type: SEND_SOCKET,
  payload: {
    event,
    data,
  },
});

export const clearSocket = () => ({
  type: CLEAR_SOCKET,
});

export const setHelpStep = (helpStep) => ({
  type: SET_HELP_STEP,
  payload: {
    helpStep,
  },
});

export const setSidebar = (sidebar) => ({
  type: SET_SIDEBAR,
  payload: {
    sidebar,
  },
});
