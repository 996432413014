import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import {
  Slider, Checkbox, MenuItem, Select,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import colors from '../libs/colors';
import { getConfigSingle } from '../redux/selectors';
import { setConfigSingle } from '../redux/actions';
import Label from './Label';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '0.15rem',
    marginBottom: '0.15rem',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  slider: {
    width: '90%',
  },
  input: {
    width: '3.5rem',
    marginLeft: '1rem',
    textAlign: 'center',
    backgroundColor: colors.drawerBackground,
    border: 'none',
    borderRadius: 3,
    fontFamily: 'Montserrat',
    fontWeight: 600,
    color: colors.contrastText,
    lineHeight: '2rem',
    height: '2rem',
  },
  select: {
    width: '100%',
    padding: '0.25rem 0.5rem',
    margin: '0.25rem 0',
    border: 'none',
    fontWeight: 600,
    fontSize: 14,
    backgroundColor: colors.selectBackground,
    color: colors.contrastText,
    '& .MuiSelect-icon': {
      color: colors.contrastText,
    },
  },
  label: {
    fontSize: 14,
    color: colors.contrastText,
  },
}));

const EkySlider = withStyles({
  root: {
    color: colors.sliderActive,
    height: 8,
  },
  thumb: {
    height: 18,
    width: 18,
    backgroundColor: colors.sliderThumb,
    marginTop: -7,
    marginLeft: -7,
    opacity: 1,
    transition: 'box-shadow 100ms linear',
    '&:focus, &:hover, &:active': {
      boxShadow: '0 0 6px #000',
    },
  },
  active: {
  },
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 4,
    borderRadius: 0,
  },
  rail: {
    height: 4,
    borderRadius: 0,
    backgroundColor: colors.sliderBackground,
    opacity: 1,
  },
})(Slider);

function Control(props) {
  const classes = useStyles();
  const {
    label,
    type,
    mapping,
    options = [],
    min = 0,
    max = 2,
    step = 0.01,
    initialValue = 0.00,
    help,
    setConfigSingle: setConfSingle,
  } = props;

  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setConfSingle(mapping, value);
  }, [value, setConfSingle, mapping]);

  const id = `${label}-${type}`;

  const RenderChild = () => {
    switch (type) {
      case 'slider':
        return (
          <>
            <EkySlider
              className={classes.slider}
              aria-labelledby={id}
              min={min}
              max={max}
              step={step}
              value={initialValue}
              onChange={(e, val) => {
                setValue(Math.min(Math.max(val, min), max));
              }}
            />
            <input
              className={classes.input}
              type="number"
              min={min}
              max={max}
              value={initialValue}
              onChange={(event) => {
                const { value: val } = event.target;
                setValue(Math.min(Math.max(val, min), max));
              }}
            />
          </>
        );
      case 'dropdown':
        return (
          <Select
            className={classes.select}
            value={initialValue}
            onChange={(event) => {
              const { value: val } = event.target;
              setValue(val);
            }}
          >
            {
              options.map((option, index) => {
                const key = `option-${index}`;
                return <MenuItem key={key} value={index}>{option.name}</MenuItem>;
              })
            }
          </Select>
        );
      case 'checkbox':
        return (
          <>
            <Checkbox
              checked={!!value}
              onChange={(e, val) => {
                setValue(val);
              }}
              name={mapping}
              color="primary"
            />
            <Label label={label} help={help} />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className={classes.root}>
      { type !== 'checkbox' ? <Label label={label} help={help} /> : null }
      <div className={classes.container}>
        { RenderChild() }
      </div>
    </div>
  );
}

export default connect(
  (state, ownProps) => ({ initialValue: getConfigSingle(state, ownProps.mapping) }),
  {
    setConfigSingle,
  },
)(Control);
