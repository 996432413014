import {
  HELP_DISPLAYED,
  SEND_SOCKET,
  CLEAR_SOCKET,
} from '../actionTypes';

const initialState = {
  socket: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case HELP_DISPLAYED: {
      return {
        ...state,
        helpDisplayed: true,
      };
    }

    case SEND_SOCKET: {
      const { event, data } = action.payload;
      return {
        ...state,
        socket: {
          event,
          data,
        },
      };
    }

    case CLEAR_SOCKET: {
      return {
        ...state,
        socket: {},
      };
    }

    default:
      return state;
  }
}
