const colors = {
  blue: '#066ca4',
  deepBlue: '#045585',
  lightBlue: '#088fc7',
  accentBlue: '#3ec5e5',
  backgroundBlue: '#013c66',
  red: '#ef1d23',
  amber: '#f68a1f',
  green: '#73b744',
  contrastText: '#ffffff',
  sliderActive: '#3dc4e2',
  sliderBackground: '#013c66',
  sliderThumb: '#ffffff',
  sliderGrey: '#d7d7d7',
  selectBackground: '#013c66',
  inputGrey: '#ebebeb',
  inputBackground: '#013c66',
  buttonGreenBorder: '#0c6b3f',
  buttonGreenIconBorder: '#98cd78',
  buttonGreenGradientStart: '#bbdb8f',
  buttonGreenGradientEnd: '#1b904d',
  buttonRedBorder: '#9e3b22',
  buttonRedIconBorder: '#f68f6b',
  buttonRedGradientStart: '#f7a28e',
  buttonRedGradientEnd: '#d02c30',
  buttonBlueBorder: '#1d97c1',
  buttonBlueIconBorder: '#34c3e2',
  buttonBlueGradientStart: '#6ccff5',
  buttonBlueGradientEnd: '#125aaa',
  modalHeader: '#3ec5e5',
  drawerBackground: '#005685',
};

export default colors;
