import React from 'react';

import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ChevronRight } from '@material-ui/icons';

import colors from '../libs/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    borderWidth: 2,
    borderStyle: 'solid',
    color: colors.contrastText,
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'capitalize',
    transition: 'opacity 250ms ease-out',
    width: '100%',
    marginTop: '0.25rem',
    marginBottom: '0.25rem',

    '&:active': {
      opacity: 0.8,
    },

    '&:first-child': {
      marginLeft: 0,
    },

    '&:last-child': {
      marginRight: 0,
    },

    '& .MuiButton-endIcon': {
      color: colors.contrastText,
      borderRadius: 25,
      borderWidth: 1,
      borderStyle: 'solid',
    },

    '&.Mui-disabled': {
      opacity: 0.4,
    },
  },
  blue: {
    backgroundImage: `linear-gradient(${colors.buttonBlueGradientStart}, ${colors.buttonBlueGradientEnd})`,
    borderColor: colors.buttonBlueBorder,

    '& .MuiButton-endIcon': {
      borderColor: colors.buttonBlueIconBorder,
      backgroundImage: `linear-gradient(${colors.buttonBlueGradientEnd}, ${colors.buttonBlueGradientStart})`,
    },
  },
  accent: {
    width: 'auto',
    backgroundImage: `linear-gradient(${colors.accentBlue}, ${colors.lightBlue})`,
    borderColor: colors.buttonBlueBorder,
    borderRadius: 0,
    borderWidth: 1,
  },
  red: {
    backgroundImage: `linear-gradient(${colors.buttonRedGradientStart}, ${colors.buttonRedGradientEnd})`,
    borderColor: colors.buttonRedBorder,

    '& .MuiButton-endIcon': {
      borderColor: colors.buttonRedIconBorder,
      backgroundImage: `linear-gradient(${colors.buttonRedGradientEnd}, ${colors.buttonRedGradientStart})`,
    },
  },
  green: {
    backgroundImage: `linear-gradient(${colors.buttonGreenGradientStart}, ${colors.buttonGreenGradientEnd})`,
    borderColor: colors.buttonGreenBorder,

    '& .MuiButton-endIcon': {
      borderColor: colors.buttonGreenIconBorder,
      backgroundImage: `linear-gradient(${colors.buttonGreenGradientEnd}, ${colors.buttonGreenGradientStart})`,
    },
  },
  marginleft: {
    marginLeft: '0.5rem',
  },
  marginright: {
    marginRight: '0.5rem',
  },
  disabled: {
    opacity: 0.4,
  },
}));

export default function GradientButton(props) {
  const classes = useStyles();
  const {
    children,
    color,
    margin,
    onClick,
    disabled,
    title,
  } = props;

  return (
    <Button
      className={`${classes.root} ${classes[color]} ${classes[`margin${margin}`]}`}
      variant="contained"
      title={title}
      size={color === 'accent' ? 'small' : 'large'}
      disabled={disabled}
      endIcon={color === 'accent' ? null : <ChevronRight />}
      onClick={onClick}
    >
      {children || null}
    </Button>
  );
}
