import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';
import * as Cookies from 'es-cookie';

import { makeStyles } from '@material-ui/core/styles';
import { Warning } from '@material-ui/icons';
import { Modal } from '@material-ui/core';

import GradientButton from './GradientButton';
import {
  openModal,
  closeModal,
  setModalMessage,
  setConfig,
  setHelpStep,
  sendSocket,
} from '../redux/actions';
import colors from '../libs/colors';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    fontFamily: theme.typography.fontFamily,
  },
  paper: {
    position: 'absolute',
    width: 680,
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    outline: 'none',
    boxShadow: theme.shadows[5],
    padding: 0,
  },
  headerContainer: {
    padding: theme.spacing(4),
    backgroundColor: colors.accentBlue,
    color: colors.contrastText,
    textAlign: 'center',
    fontFamily: theme.typography.fontFamily,
  },
  bodyContainer: {
    padding: theme.spacing(4, 4, 2),
    color: colors.deepBlue,
    textAlign: 'center',
    fontFamily: theme.typography.fontFamily,
  },
  buttonContainer: {
    display: 'flex',
    padding: theme.spacing(2, 4, 4),
  },
  buttonContainerSingle: {
    display: 'flex',
    width: '50%',
    margin: 'auto',
  },
  debugContainer: {
    whiteSpace: 'normal',
    overflowWrap: 'anywhere',
    padding: theme.spacing(1),
    backgroundColor: '#eaeaea',
  },
  textarea: {
    width: '100%',
    height: '7rem',
    resize: 'none',
    padding: theme.spacing(1),
    fontFamily: theme.typography.fontFamily,
    marginTop: theme.spacing(2),
    fontSize: 14,
  },
}));

function ToolModal(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);
  const [report, setReport] = useState(null);

  const {
    ui,
    config,
    arid,
    checksum,
  } = props;
  const { modal, message, debug } = ui;

  const handleClose = () => {
    props.closeModal();
  };

  const handleQuit = () => {
    const { onClose } = props;
    if (onClose) {
      onClose();
    }
  };

  const { enqueueSnackbar } = useSnackbar();

  const MESSAGE = {
    FIRST_LOAD: {
      title: 'Welcome to the Calibration Tool!',
      description: 'Your 3D/AR model has already been pre-calibrated to match your original imagery as closely as possible. If you would like to make adjustments please use the Manual Calibration options.',
      buttons: (
        <>
          <GradientButton
            margin="right"
            color="green"
            onClick={() => {
              const tutorialPlayed = Cookies.get('tutorial');
              if (!tutorialPlayed) {
                props.setHelpStep(0);
              }
              handleClose();
            }}
          >
            Manual Calibration
          </GradientButton>
          <GradientButton
            margin="left"
            color="red"
            onClick={handleQuit}
          >
            Back to the tracker
          </GradientButton>
        </>
      ),
    },
    SAVE_CONFIRM: {
      title: 'Save and upload for online distribution?',
      description: 'This will save your adjustments and add the model into the distribution database for you to select where it goes live, eg your own website, retailers etc.',
      buttons: (
        <>
          <GradientButton
            margin="right"
            color="green"
            onClick={() => {
              props.setModalMessage('SAVE_PROGRESS');

              const data = {
                checksum,
                fileName: arid,
                jsonData: config,
              };
              props.sendSocket('client-calibration-save', data);
            }}
          >
            Yes
          </GradientButton>
          <GradientButton
            margin="left"
            color="red"
            onClick={handleClose}
          >
            No
          </GradientButton>
        </>
      ),
    },
    SAVE_SUCCESSFUL: {
      title: 'Congratulations!',
      description: 'Your models are now approved - please allow 24 hours for your models to be optimised for online distribution. The distribution links will appear under the "GoLive" button in your Tracker.',
      buttons: (
        <div className={classes.buttonContainerSingle}>
          <GradientButton color="green" onClick={handleQuit}>
            Close
          </GradientButton>
        </div>
      ),
    },
    ERROR: {
      title: 'Something has gone wrong!',
      description: (
        <span>
          Please try again or contact us at
          {' '}
          <a href={`mailto:prioritysupport@eyekandy.com&subject=${encodeURIComponent(`[EKYCalRef: ${arid || Date.now()}] EKY Calibration Tool Failure`)}&body=${encodeURIComponent(debug)}`}>prioritysupport@eyekandy.com</a>
          {' '}
          with the following information:
        </span>
      ),
      buttons: (
        <div className={classes.buttonContainerSingle}>
          <GradientButton color="blue" onClick={handleQuit}>
            Close
          </GradientButton>
        </div>
      ),
    },
    SAVE_PROGRESS: {
      title: 'Please wait',
      description: 'We are saving the current calibration on our servers...',
      buttons: null,
    },
    RESET_CONFIRM: {
      title: 'Reset the calibration?',
      description: 'This will reset all of your adjustments and roll back to the previous calibrated settings, press cancel if you wish to retain your current settings.',
      buttons: (
        <>
          <GradientButton
            margin="right"
            color="red"
            onClick={() => {
              props.setConfig(JSON.parse(JSON.stringify(window.originalConfig)));
              enqueueSnackbar('The calibration adjustments have been reset', {
                variant: 'success',
              });

              handleClose();
            }}
          >
            Reset settings
          </GradientButton>
          <GradientButton
            margin="left"
            color="blue"
            onClick={handleClose}
          >
            Cancel
          </GradientButton>
        </>
      ),
    },
    REPORT_ISSUE: {
      title: (
        <>
          <Warning style={{ verticalAlign: 'bottom' }} fontSize="large" />
          <span> Report an Issue</span>
        </>
      ),
      description: (
        <>
          <span>
            Report an issue to EyeKandy and our team will get back to you shortly!
          </span>
          <textarea
            onChange={(e) => setReport(e.target.value)}
            className={classes.textarea}
          />
        </>
      ),
      buttons: (
        <>
          <GradientButton
            color="green"
            onClick={() => {
              const { onReport } = props;
              if (onReport) {
                onReport(report);
              }

              handleClose();
            }}
          >
            Send message
          </GradientButton>
          <GradientButton
            margin="left"
            color="blue"
            onClick={handleClose}
          >
            Cancel
          </GradientButton>
        </>
      ),
    },
  };

  if (message === null) {
    return null;
  }

  const currentMessage = MESSAGE[message];

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <div id="simple-modal-title" className={classes.headerContainer}>
        <h2>{currentMessage.title}</h2>
      </div>
      <div id="simple-modal-description" className={classes.bodyContainer}>
        <p>{currentMessage.description}</p>
        { debug ? <pre className={classes.debugContainer}>{debug}</pre> : null}
      </div>
      <div className={classes.buttonContainer}>{currentMessage.buttons}</div>
    </div>
  );

  return (
    <Modal
      open={modal}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
}

export default connect(
  (state) => ({ ui: state.ui, config: state.config }),
  {
    openModal,
    closeModal,
    setModalMessage,
    setConfig,
    setHelpStep,
    sendSocket,
  },
)(ToolModal);
