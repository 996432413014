/* eslint-disable import/prefer-default-export */
/* eslint-disable multiline-ternary */
/* eslint-disable no-nested-ternary */
import { reduce, isArray, isPlainObject } from 'lodash';

const bdiff = (a, b) => reduce(a, (res, val, key) => res.concat((isPlainObject(val) || isArray(val)) && b
  ? bdiff(val, b[key]).map((x) => `${key}.${x}`)
  : (!b || val !== b[key] ? [key] : [])), []);

export function diff(a, b) {
  const u = bdiff(a, b);
  const v = bdiff(b, a);

  return u.filter((x) => !v.includes(x))
    .concat(u.filter((x) => v.includes(x)))
    .concat(v.filter((x) => !u.includes(x)));
}
