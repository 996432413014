import React from 'react';
import { connect } from 'react-redux';
import * as Cookies from 'es-cookie';

import { AppBar, Toolbar, IconButton, Button } from '@material-ui/core';
import { Help, Warning } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { setHelpStep, setModalMessage, openModal } from '../redux/actions';
import HelpPopper from './HelpPopper';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1000,
    boxShadow: 'none',
    fontFamily: theme.typography.fontFamily,
  },
  metadata: {
    flexGrow: 1,
  },
  helpButton: {
    marginLeft: theme.spacing(2),
    // border: '2px solid white',
  },
}));

function Header(props) {
  const classes = useStyles();
  const {
    ui,
    arid,
    project,
    product,
  } = props;

  return (
    <AppBar color="secondary" position="absolute" className={classes.root}>
      <Toolbar>
        <div className={classes.metadata}>
          <p className="big">
            <strong>
              Project:
              {project}
            </strong>
            {' '}
            &mdash;
            {' '}
            {product}
          </p>
          <p>
            <strong>
              ARID:
              {arid}
            </strong>
          </p>
        </div>
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<Warning />}
          onClick={() => {
            props.setModalMessage('REPORT_ISSUE');
            props.openModal();
          }}
        >
          Report an Issue
        </Button>
        <HelpPopper
          open={ui.helpStep === 5}
          text="To view this tutorial again, click on this help icon at any time"
          placement="bottom"
          x="-100"
          onClick={() => {
            props.setHelpStep(null);
            Cookies.set('tutorial', 'done');
          }}
        />
        {ui.sidebar === 'sliders' && (
        <IconButton
          className={classes.helpButton}
          color="inherit"
          onClick={() => {
            if (ui.sidebar === 'sliders') {
              props.setHelpStep(0);
            } else {
              props.setHelpStep('initial-1');
            }
          }}
        >
          <Help />
        </IconButton>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default connect(
  (state) => ({ ui: state.ui }),
  {
    setHelpStep,
    setModalMessage,
    openModal,
  },
)(Header);
