import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Popper } from '@material-ui/core';
import colors from '../libs/colors';

import GradientButton from './GradientButton';

const arrowSize = 8;

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    fontFamily: theme.typography.fontFamily,
  },
  anchor: {
    width: '100%',
    display: 'block',
    height: 0,
  },
  popper: {
    zIndex: 1005,
    boxShadow: theme.shadows[5],

    '&[x-placement*="bottom"] .arrow': {
      borderLeft: `${arrowSize}px solid transparent`,
      borderRight: `${arrowSize}px solid transparent`,
      borderBottom: `${arrowSize}px solid ${colors.accentBlue}`,
    },

    '&[x-placement*="top"] .arrow': {
      bottom: arrowSize * -2,
      borderLeft: `${arrowSize}px solid transparent`,
      borderRight: `${arrowSize}px solid transparent`,
      borderTop: `${arrowSize}px solid ${colors.accentBlue}`,
    },

    '&[x-placement*="right"] .arrow': {
      left: arrowSize * -2,
      borderTop: `${arrowSize}px solid transparent`,
      borderBottom: `${arrowSize}px solid transparent`,
      borderRight: `${arrowSize}px solid ${colors.accentBlue}`,
    },

    '&[x-placement*="left"] .arrow': {
      right: arrowSize * -2,
      borderTop: `${arrowSize}px solid transparent`,
      borderBottom: `${arrowSize}px solid transparent`,
      borderLeft: `${arrowSize}px solid ${colors.accentBlue}`,
    },

    '& .arrow': {
      position: 'absolute',
      width: arrowSize * 2,
      height: arrowSize * 2,
    },
  },
  paper: {
    padding: theme.spacing(1, 1, 0),
    backgroundColor: colors.accentBlue,
    borderRadius: 2,
    color: colors.contrastText,
    fontSize: 14,
    maxWidth: 300,

    '& div': {
      padding: theme.spacing(0.5, 0),
      fontFamily: theme.typography.fontFamily,
    },
  },
  btnContainer: {
    textAlign: 'right',
  },
  title: {
    fontSize: 16,
  },
}));

export default function HelpPopper(props) {
  const classes = useStyles();
  const {
    children,
    title,
    text,
    open,
    onClick,
    placement,
    x = -20,
    y = 40,
  } = props;

  const [arrowRef, setArrowRef] = useState(null);
  const [anchorRef, setAnchorRef] = useState(null);

  const popperModifiers = {
    flip: {
      enabled: true,
    },
    preventOverflow: {
      enabled: true,
      boundariesElement: 'viewport',
      escapeWithReference: true,
    },
    arrow: {
      enabled: true,
      element: arrowRef,
    },
  };

  const init = Boolean(anchorRef) && open;
  const id = open ? 'transitions-popper' : undefined;

  return (
    <div className={classes.root}>
      <div
        style={children ? {} : { transform: `translate(${x}px, ${y}px)` }}
        className={classes.anchor}
        ref={setAnchorRef}
      >
        {children || null}
      </div>
      <Popper
        id={id}
        open={init}
        anchorEl={anchorRef}
        placement={placement || 'left'}
        disablePortal={false}
        modifiers={popperModifiers}
        className={classes.popper}
        transition
      >
        { init ? <span className="arrow" ref={setArrowRef} /> : null }
        <div className={classes.paper}>
          {title ? <div className={classes.title}><strong>{title}</strong></div> : null}
          {text ? <div>{text}</div> : null}
          {onClick ? <div className={classes.btnContainer}><GradientButton color="accent" onClick={onClick}>Got it!</GradientButton></div> : null}
        </div>
      </Popper>
    </div>
  );
}
