import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { IconButton } from '@material-ui/core';

import icons from '../libs/icons';
import colors from '../libs/colors';

const useStyles = makeStyles(() => ({
  root: {
    height: '8.75rem',
    overflowY: 'scroll',
    margin: '0.5rem 0',
    padding: '0.25rem',
    backgroundColor: colors.inputBackground,
  },
}));

export default function Typcn(props) {
  const classes = useStyles();

  const {
    defaultValue = null,
    onSelect,
  } = props;

  const [value, setValue] = useState(defaultValue);

  return (
    <div className={classes.root}>
      {icons.map((icon) => (
        <IconButton
          type="button"
          key={icon.name}
          className={`icon icon-button ${value === icon.name ? 'active' : ''}`}
          data-name={icon.name}
          data-code={icon.code}
          data-match={icon.match}
          onClick={() => {
            if (onSelect) {
              onSelect(icon.name);
            }
            setValue(icon.name);
          }}
        >
          <div className={`typcn icon-default ${icon.name}`} />
        </IconButton>
      ))}
    </div>
  );
}
