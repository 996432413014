import React from 'react';
import { connect } from 'react-redux';

import {
  Divider,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  Tune,
  CenterFocusWeak,
} from '@material-ui/icons';

import Control from './Control';
import MultiControl from './MultiControl';
import Collapsible from './Collapsible';
import GradientButton from './GradientButton';
import HelpPopper from './HelpPopper';
import { getConfig } from '../redux/selectors';
import envmaps from '../libs/envmaps';
import colors from '../libs/colors';
import HotspotManager from './HotspotManager';
import {
  openModal, setModalMessage, setConfig, setHelpStep,
} from '../redux/actions';
import sizes from '../libs/sizes';

const useStyles = makeStyles(() => ({
  drawerContainer: {
    padding: '1.5rem',
    marginBottom: 100,
    overflow: 'auto',
    paddingTop: 0,
  },
  divider: {
    marginTop: '1.25rem',
    marginBottom: '1.25rem',
    backgroundColor: colors.inputGrey,
    opacity: 0.3,
  },
  button: {
    width: '100%',
    textTransform: 'capitalize',
    textAlign: 'left',
  },
  h6: {
    color: colors.contrastText,
    marginBottom: '1.5rem',
  },
  buttonContainer: {
    bottom: 0,
    right: 0,
    width: sizes.drawerWidth,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1.5rem',
    backgroundColor: colors.drawerBackground,

    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 100,
      right: 11,
      width: sizes.drawerWidth - 11,
      height: '1rem',
      background: `linear-gradient(to top, ${colors.drawerBackground}, transparent)`,
      display: 'block',
    },
  },
}));

const ManualControls = [
  {
    type: 'slider',
    mapping: 'post.image.brightness',
    label: 'Brightness',
    min: -100,
    max: 100,
    step: 1,
    help: 'Overall lightness or darkness of your model',
  },
  {
    type: 'slider',
    mapping: 'post.image.contrast',
    label: 'Contrast',
    min: 1,
    max: 5,
    help: 'Distinction between lighter and darker areas of your model',
  },
  {
    type: 'divider',
  },
  {
    type: 'slider',
    mapping: 'shadows.darkness',
    label: 'Shadow Intensity',
    min: 0,
    max: 1,
    help: 'Intensity of shadows casted from scene lights on your model',
  },
  {
    type: 'slider',
    mapping: 'shadows.blur',
    label: 'Shadow Blur',
    min: 0,
    max: 256,
    step: 2,
    help: 'Softness of shadows on the model',
  },
  {
    type: 'divider',
  },
  {
    type: 'slider',
    mapping: 'lights.ambient.intensity',
    label: 'Ambient Light Intensity',
    min: 0,
    max: 2.5,
    help: 'Strength of general lighting on the model',
  },
  {
    type: 'divider',
  },
  {
    type: 'slider',
    mapping: 'environment.amount',
    label: 'Reflectivity',
    min: 0,
    max: 3,
    help: 'Strength of the reflective texture shining on metal and glass surfaces',
  },
  {
    type: 'slider',
    mapping: 'post.image.saturation',
    label: 'Saturation',
    min: -100,
    max: 100,
    step: 1,
    help: 'Intensity of colour in your model',
  },
];

const AdvancedControls = [
  {
    type: 'slider',
    mapping: 'post.image.exposure',
    label: 'Exposure',
    min: 0.8,
    max: 1.5,
    help: 'Tonal range of captured light in your model',
  },
  {
    type: 'divider',
  },
  {
    type: 'multi',
    mapping: [
      'lights.ambient.direction.x',
      'lights.ambient.direction.y',
      'lights.ambient.direction.z',
      'lights.ambient.color',
    ],
    label: 'Ambient Light Position and Colour',
    help: 'Adjusts the position and the colour of the ambient light',
  },
  {
    type: 'divider',
  },
  {
    type: 'slider',
    mapping: 'lights.directional.light01.intensity',
    label: 'Light 1 Intensity',
    min: 0,
    max: 2.5,
    help: 'Strength of the shine in the first scene light of your model',
  },
  {
    type: 'multi',
    mapping: [
      'lights.directional.light01.direction.x',
      'lights.directional.light01.direction.y',
      'lights.directional.light01.direction.z',
      'lights.directional.light01.color',
    ],
    label: 'Light 1 Position and Colour',
    help: 'Adjusts the position and colour of the first scene light',
  },
  {
    type: 'divider',
  },
  {
    type: 'slider',
    mapping: 'lights.directional.light02.intensity',
    label: 'Light 2 Intensity',
    min: 0,
    max: 2.5,
    help: 'Strength of the shine in the second scene light of your model',
  },
  {
    type: 'multi',
    mapping: [
      'lights.directional.light02.direction.x',
      'lights.directional.light02.direction.y',
      'lights.directional.light02.direction.z',
      'lights.directional.light02.color',
    ],
    label: 'Light 2 Position and Colour',
    help: 'Adjusts the position and colour of the second scene light',
  },
  {
    type: 'divider',
  },
  {
    type: 'slider',
    mapping: 'lights.directional.light03.intensity',
    label: 'Light 3 Intensity',
    min: 0,
    max: 2.5,
    help: 'Strength of the shine in the third scene light of your model',
  },
  {
    type: 'multi',
    mapping: [
      'lights.directional.light03.direction.x',
      'lights.directional.light03.direction.y',
      'lights.directional.light03.direction.z',
      'lights.directional.light03.color',
    ],
    label: 'Light 3 Position and Colour',
    help: 'Adjusts the position and colour of the third scene light',
  },
  {
    type: 'divider',
  },
  {
    type: 'dropdown',
    mapping: 'environment.map',
    label: 'Reflection Map Texture',
    options: envmaps,
    help: 'Applies a texture onto reflective materials, working best on shiny surfaces like glass and metal',
  },
  {
    type: 'checkbox',
    mapping: 'environment.seeUnder',
    label: 'Allow Camera to see Under Model?',
    help: 'Allows the camera to rotate under your model',
  },
  {
    type: 'checkbox',
    mapping: 'environment.allowPanning',
    label: 'Allow Camera Panning',
    help: 'Allows the camera to be panned across the scene when dragging with the right mouse button',
  },
];

function SidebarSliders(props) {
  const classes = useStyles();

  const { ui } = props;

  const RenderControl = (el, i, parentkey) => {
    const key = `${parentkey}-${el.type}-${i}`;

    switch (el.type) {
      case 'divider':
        return (<Divider key={key} className={classes.divider} />);

      case 'multi': {
        return (
          <MultiControl
            key={key}
            type={el.type}
            label={el.label}
            mapping={el.mapping}
            help={el.help}
          />
        );
      }

      default: {
        return (
          <Control
            key={key}
            type={el.type}
            label={el.label}
            step={el.step}
            min={el.min}
            max={el.max}
            mapping={el.mapping}
            options={el.options}
            help={el.help}
          />
        );
      }
    }
  };

  return (
    <div className={classes.drawerContainer}>
      <HelpPopper
        x="-20"
        y="20"
        open={ui.helpStep === 0}
        text="These settings will allow you to manually apply parameters that meet your preference for your model"
        onClick={() => {
          props.setHelpStep(1);
        }}
      />
      <Typography variant="h6" weight="600" className={classes.h6}>
        Manual Calibration
      </Typography>
      <HelpPopper
        open={ui.helpStep === 1}
        title="Step 1"
        text="Use the sliders in this panel to adjust the calibration of your model"
        onClick={() => {
          props.setHelpStep(2);
        }}
      />
      {
        ManualControls.map((control, index) => (RenderControl(control, index, 'manual')))
      }

      <HelpPopper
        open={ui.helpStep === 2}
        y="30"
        title="Step 2"
        text="Click the Advanced Settings dropdown to access more in-depth settings"
        onClick={() => {
          props.setHelpStep(3);
        }}
      />
      <Collapsible icon={<Tune />} label="Advanced Settings">
        {
          AdvancedControls.map((control, index) => (RenderControl(control, index, 'advanced')))
        }
        <GradientButton
          color="blue"
          onClick={() => {
            window.camera.restoreState();
          }}
        >
          Reset Camera
        </GradientButton>
      </Collapsible>

      <HelpPopper
        open={ui.helpStep === 3}
        y="23"
        title="Step 3"
        text="Click on the Hotspots dropdown to create and edit interactive Hotspots for your model"
        onClick={() => {
          props.setHelpStep(4);
        }}
      />
      <Collapsible icon={<CenterFocusWeak />} label="Hotspots">
        <HotspotManager />
      </Collapsible>

      <div className={classes.buttonContainer}>
        <HelpPopper
          open={ui.helpStep === 4}
          title="Step 4"
          text="Click here to save all your adjustments and add your model into the distribution database"
          placement="top"
          x="100"
          y="-5"
          onClick={() => {
            props.setHelpStep(5);
          }}
        />
        <GradientButton
          color="green"
          margin="right"
          onClick={() => {
            props.setModalMessage('SAVE_CONFIRM');
            props.openModal();
          }}
        >
          Save calibration
        </GradientButton>
        <GradientButton
          color="red"
          margin="left"
          onClick={() => {
            props.setModalMessage('RESET_CONFIRM');
            props.openModal();
          }}
        >
          Reset to default
        </GradientButton>
      </div>
    </div>
  );
}

export default connect(
  (state) => ({ ui: state.ui, config: getConfig(state) }),
  {
    openModal,
    setModalMessage,
    setConfig,
    setHelpStep,
  },
)(SidebarSliders);
