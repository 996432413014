const icons = [
  { name: 'typcn-adjust-brightness', code: '0xe000', match: 'sun adjust brightness' },
  { name: 'typcn-adjust-contrast', code: '0xe001', match: 'half adjust contrast' },
  { name: 'typcn-anchor-outline', code: '0xe002', match: 'anchor outline' },
  { name: 'typcn-anchor', code: '0xe003', match: 'anchor' },
  { name: 'typcn-archive', code: '0xe004', match: 'archive' },
  { name: 'typcn-arrow-back-outline', code: '0xe005', match: 'arrow back outline' },
  { name: 'typcn-arrow-back', code: '0xe006', match: 'arrow back' },
  { name: 'typcn-arrow-down-outline', code: '0xe007', match: 'arrow down outline' },
  { name: 'typcn-arrow-down-thick', code: '0xe008', match: 'arrow down thick' },
  { name: 'typcn-arrow-down', code: '0xe009', match: 'arrow down' },
  { name: 'typcn-arrow-forward-outline', code: '0xe00a', match: 'arrow forward outline' },
  { name: 'typcn-arrow-forward', code: '0xe00b', match: 'arrow forward' },
  { name: 'typcn-arrow-left-outline', code: '0xe00c', match: 'arrow left outline' },
  { name: 'typcn-arrow-left-thick', code: '0xe00d', match: 'arrow left thick' },
  { name: 'typcn-arrow-left', code: '0xe00e', match: 'arrow left' },
  { name: 'typcn-arrow-loop-outline', code: '0xe00f', match: 'arrow loop outline' },
  { name: 'typcn-arrow-loop', code: '0xe010', match: 'arrow loop' },
  { name: 'typcn-arrow-maximise-outline', code: '0xe011', match: 'arrow maximise outline' },
  { name: 'typcn-arrow-maximise', code: '0xe012', match: 'arrow maximise' },
  { name: 'typcn-arrow-minimise-outline', code: '0xe013', match: 'arrow minimise outline' },
  { name: 'typcn-arrow-minimise', code: '0xe014', match: 'arrow minimise' },
  { name: 'typcn-arrow-move-outline', code: '0xe015', match: 'arrow move outline' },
  { name: 'typcn-arrow-move', code: '0xe016', match: 'arrow move' },
  { name: 'typcn-arrow-repeat-outline', code: '0xe017', match: 'arrow repeat outline' },
  { name: 'typcn-arrow-repeat', code: '0xe018', match: 'arrow repeat' },
  { name: 'typcn-arrow-right-outline', code: '0xe019', match: 'arrow right outline' },
  { name: 'typcn-arrow-right-thick', code: '0xe01a', match: 'arrow right thick' },
  { name: 'typcn-arrow-right', code: '0xe01b', match: 'arrow right' },
  { name: 'typcn-arrow-shuffle', code: '0xe01c', match: 'arrow shuffle' },
  { name: 'typcn-arrow-sorted-down', code: '0xe01d', match: 'arrow sorted down' },
  { name: 'typcn-arrow-sorted-up', code: '0xe01e', match: 'arrow sorted up' },
  { name: 'typcn-arrow-sync-outline', code: '0xe01f', match: 'arrow sync outline' },
  { name: 'typcn-arrow-sync', code: '0xe020', match: 'arrow sync' },
  { name: 'typcn-arrow-unsorted', code: '0xe021', match: 'arrow unsorted' },
  { name: 'typcn-arrow-up-outline', code: '0xe022', match: 'arrow up outline' },
  { name: 'typcn-arrow-up-thick', code: '0xe023', match: 'arrow up thick' },
  { name: 'typcn-arrow-up', code: '0xe024', match: 'arrow up' },
  { name: 'typcn-at', code: '0xe025', match: 'at' },
  { name: 'typcn-attachment-outline', code: '0xe026', match: 'attachment outline' },
  { name: 'typcn-attachment', code: '0xe027', match: 'attachment' },
  { name: 'typcn-backspace-outline', code: '0xe028', match: 'delete backspace outline' },
  { name: 'typcn-backspace', code: '0xe029', match: 'delete backspace' },
  { name: 'typcn-battery-charge', code: '0xe02a', match: 'power battery charge' },
  { name: 'typcn-battery-full', code: '0xe02b', match: 'power battery full' },
  { name: 'typcn-battery-high', code: '0xe02c', match: 'power battery high' },
  { name: 'typcn-battery-low', code: '0xe02d', match: 'power battery low' },
  { name: 'typcn-battery-mid', code: '0xe02e', match: 'power battery mid' },
  { name: 'typcn-beaker', code: '0xe02f', match: 'lab beta experiment beaker' },
  { name: 'typcn-beer', code: '0xe030', match: 'ale lager beer' },
  { name: 'typcn-bell', code: '0xe031', match: 'tone alarm bell' },
  { name: 'typcn-book', code: '0xe032', match: 'book' },
  { name: 'typcn-bookmark', code: '0xe033', match: 'banner flag bookmark' },
  { name: 'typcn-briefcase', code: '0xe034', match: 'briefcase' },
  { name: 'typcn-brush', code: '0xe035', match: 'brush' },
  { name: 'typcn-business-card', code: '0xe036', match: 'id business card' },
  { name: 'typcn-calculator', code: '0xe037', match: 'calculator' },
  { name: 'typcn-calendar-outline', code: '0xe038', match: 'calendar outline' },
  { name: 'typcn-calendar', code: '0xe039', match: 'calendar' },
  { name: 'typcn-camera-outline', code: '0xe03a', match: 'camera outline' },
  { name: 'typcn-camera', code: '0xe03b', match: 'photo camera' },
  { name: 'typcn-cancel-outline', code: '0xe03c', match: 'photo cancel outline' },
  { name: 'typcn-cancel', code: '0xe03d', match: 'cancel' },
  { name: 'typcn-chart-area-outline', code: '0xe03e', match: 'graph chart area outline' },
  { name: 'typcn-chart-area', code: '0xe03f', match: 'graph chart area' },
  { name: 'typcn-chart-bar-outline', code: '0xe040', match: 'graph chart bar outline' },
  { name: 'typcn-chart-bar', code: '0xe041', match: 'graph chart bar' },
  { name: 'typcn-chart-line-outline', code: '0xe042', match: 'graph chart line outline' },
  { name: 'typcn-chart-line', code: '0xe043', match: 'graph chart line' },
  { name: 'typcn-chart-pie-outline', code: '0xe044', match: 'graph chart pie outline' },
  { name: 'typcn-chart-pie', code: '0xe045', match: 'graph chart pie' },
  { name: 'typcn-chevron-left-outline', code: '0xe046', match: 'less than chevron left outline' },
  { name: 'typcn-chevron-left', code: '0xe047', match: 'less than chevron left' },
  { name: 'typcn-chevron-right-outline', code: '0xe048', match: null },
  { name: 'typcn-chevron-right', code: '0xe049', match: 'greater than chevron right' },
  { name: 'typcn-clipboard', code: '0xe04a', match: 'copy clipboard' },
  { name: 'typcn-cloud-storage', code: '0xe04b', match: 'data upload cloud storage' },
  { name: 'typcn-cloud-storage-outline', code: '0xe054', match: null },
  { name: 'typcn-code-outline', code: '0xe04c', match: 'tag code outline' },
  { name: 'typcn-code', code: '0xe04d', match: 'tag code' },
  { name: 'typcn-coffee', code: '0xe04e', match: 'beverage coffee' },
  { name: 'typcn-cog-outline', code: '0xe04f', match: 'settings cog outline' },
  { name: 'typcn-cog', code: '0xe050', match: 'settings cog' },
  { name: 'typcn-compass', code: '0xe051', match: 'safari compass' },
  { name: 'typcn-contacts', code: '0xe052', match: 'address book contacts' },
  { name: 'typcn-credit-card', code: '0xe053', match: 'payment credit card' },
  { name: 'typcn-css3', code: '0xe055', match: 'css3' },
  { name: 'typcn-database', code: '0xe056', match: 'db database' },
  { name: 'typcn-delete-outline', code: '0xe057', match: 'cross close delete outline' },
  { name: 'typcn-delete', code: '0xe058', match: 'cross close delete' },
  { name: 'typcn-device-desktop', code: '0xe059', match: 'pc mac device desktop' },
  { name: 'typcn-device-laptop', code: '0xe05a', match: 'notebook macbook device laptop' },
  { name: 'typcn-device-phone', code: '0xe05b', match: 'iphone device phone' },
  { name: 'typcn-device-tablet', code: '0xe05c', match: 'ipad device tablet' },
  { name: 'typcn-directions', code: '0xe05d', match: 'directions' },
  { name: 'typcn-divide-outline', code: '0xe05e', match: 'division divide outline' },
  { name: 'typcn-divide', code: '0xe05f', match: 'division divide' },
  { name: 'typcn-document-add', code: '0xe060', match: 'file document add' },
  { name: 'typcn-document-delete', code: '0xe061', match: 'file document delete' },
  { name: 'typcn-document-text', code: '0xe062', match: 'file document text' },
  { name: 'typcn-document', code: '0xe063', match: 'file document' },
  { name: 'typcn-download-outline', code: '0xe064', match: 'download outline' },
  { name: 'typcn-download', code: '0xe065', match: 'download' },
  { name: 'typcn-dropbox', code: '0xe066', match: 'dropbox' },
  { name: 'typcn-edit', code: '0xe067', match: 'pencil edit' },
  { name: 'typcn-eject-outline', code: '0xe068', match: 'export log off out eject outline' },
  { name: 'typcn-eject', code: '0xe069', match: 'export log off out eject' },
  { name: 'typcn-equals-outline', code: '0xe06a', match: 'equals outline' },
  { name: 'typcn-equals', code: '0xe06b', match: 'equals' },
  { name: 'typcn-export-outline', code: '0xe06c', match: 'share export outline' },
  { name: 'typcn-export', code: '0xe06d', match: 'share export' },
  { name: 'typcn-eye-outline', code: '0xe06e', match: 'view eye outline' },
  { name: 'typcn-eye', code: '0xe06f', match: 'view eye' },
  { name: 'typcn-feather', code: '0xe070', match: 'feather' },
  { name: 'typcn-film', code: '0xe071', match: 'strip film' },
  { name: 'typcn-filter', code: '0xe072', match: 'funnel refine filter' },
  { name: 'typcn-flag-outline', code: '0xe073', match: 'flag outline' },
  { name: 'typcn-flag', code: '0xe074', match: 'flag' },
  { name: 'typcn-flash-outline', code: '0xe075', match: 'power lightning flash outline' },
  { name: 'typcn-flash', code: '0xe076', match: 'power lightning flash' },
  { name: 'typcn-flow-children', code: '0xe077', match: 'flow children' },
  { name: 'typcn-flow-merge', code: '0xe078', match: 'flow merge' },
  { name: 'typcn-flow-parallel', code: '0xe079', match: 'flow parallel' },
  { name: 'typcn-flow-switch', code: '0xe07a', match: 'flow switch' },
  { name: 'typcn-folder-add', code: '0xe07b', match: 'directory folder add' },
  { name: 'typcn-folder-delete', code: '0xe07c', match: 'directory folder delete' },
  { name: 'typcn-folder-open', code: '0xe07d', match: 'directory folder open' },
  { name: 'typcn-folder', code: '0xe07e', match: 'directory folder' },
  { name: 'typcn-gift', code: '0xe07f', match: 'present gift' },
  { name: 'typcn-globe-outline', code: '0xe080', match: 'globe outline' },
  { name: 'typcn-globe', code: '0xe081', match: 'globe' },
  { name: 'typcn-group-outline', code: '0xe082', match: 'users group outline' },
  { name: 'typcn-group', code: '0xe083', match: 'users group' },
  { name: 'typcn-headphones', code: '0xe084', match: 'headphones' },
  { name: 'typcn-heart-full-outline', code: '0xe085', match: null },
  { name: 'typcn-heart-half-outline', code: '0xe086', match: null },
  { name: 'typcn-heart-outline', code: '0xe087', match: 'like favourite love heart outline' },
  { name: 'typcn-heart', code: '0xe088', match: 'like favourite love heart' },
  { name: 'typcn-home-outline', code: '0xe089', match: 'home outline' },
  { name: 'typcn-home', code: '0xe08a', match: 'home' },
  { name: 'typcn-html5', code: '0xe08b', match: 'html5' },
  { name: 'typcn-image-outline', code: '0xe08c', match: 'picture photo image outline' },
  { name: 'typcn-image', code: '0xe08d', match: 'picture photo image' },
  { name: 'typcn-infinity-outline', code: '0xe08e', match: 'infinity outline' },
  { name: 'typcn-infinity', code: '0xe08f', match: 'infinity' },
  { name: 'typcn-info-large-outline', code: '0xe090', match: 'info large outline' },
  { name: 'typcn-info-large', code: '0xe091', match: 'info large' },
  { name: 'typcn-info-outline', code: '0xe092', match: 'info outline' },
  { name: 'typcn-info', code: '0xe093', match: 'info' },
  { name: 'typcn-input-checked-outline', code: '0xe094', match: null },
  { name: 'typcn-input-checked', code: '0xe095', match: 'tick correct input checked' },
  { name: 'typcn-key-outline', code: '0xe096', match: 'password login key outline' },
  { name: 'typcn-key', code: '0xe097', match: 'password login key' },
  { name: 'typcn-keyboard', code: '0xe098', match: 'keyboard' },
  { name: 'typcn-leaf', code: '0xe099', match: 'leaf' },
  { name: 'typcn-lightbulb', code: '0xe09a', match: 'idea lightbulb' },
  { name: 'typcn-link-outline', code: '0xe09b', match: 'chain link outline' },
  { name: 'typcn-link', code: '0xe09c', match: 'chain link' },
  { name: 'typcn-location-arrow-outline', code: '0xe09d', match: null },
  { name: 'typcn-location-arrow', code: '0xe09e', match: 'direction location arrow' },
  { name: 'typcn-location-outline', code: '0xe09f', match: 'map pin location outline' },
  { name: 'typcn-location', code: '0xe0a0', match: 'map pin location' },
  { name: 'typcn-lock-closed-outline', code: '0xe0a1', match: 'locked lock closed outline' },
  { name: 'typcn-lock-closed', code: '0xe0a2', match: 'locked lock closed' },
  { name: 'typcn-lock-open-outline', code: '0xe0a3', match: 'lock open outline' },
  { name: 'typcn-lock-open', code: '0xe0a4', match: 'lock open' },
  { name: 'typcn-mail', code: '0xe0a5', match: 'email mail' },
  { name: 'typcn-map', code: '0xe0a6', match: 'brochure pamphlet map' },
  { name: 'typcn-media-eject-outline', code: '0xe0a7', match: 'media eject outline' },
  { name: 'typcn-media-eject', code: '0xe0a8', match: 'media eject' },
  { name: 'typcn-media-fast-forward-outline', code: '0xe0a9', match: 'media fast forward outline' },
  { name: 'typcn-media-fast-forward', code: '0xe0aa', match: 'media fast forward' },
  { name: 'typcn-media-pause-outline', code: '0xe0ab', match: 'media pause outline' },
  { name: 'typcn-media-pause', code: '0xe0ac', match: 'media pause' },
  { name: 'typcn-media-play-outline', code: '0xe0ad', match: 'media play outline' },
  { name: 'typcn-media-play-reverse-outline', code: '0xe0ae', match: 'media play reverse outline' },
  { name: 'typcn-media-play-reverse', code: '0xe0af', match: 'media play reverse' },
  { name: 'typcn-media-play', code: '0xe0b0', match: 'media play' },
  { name: 'typcn-media-record-outline', code: '0xe0b1', match: 'media record outline' },
  { name: 'typcn-media-record', code: '0xe0b2', match: 'media record' },
  { name: 'typcn-media-rewind-outline', code: '0xe0b3', match: 'media rewind outline' },
  { name: 'typcn-media-rewind', code: '0xe0b4', match: 'media rewind' },
  { name: 'typcn-media-stop-outline', code: '0xe0b5', match: 'media stop outline' },
  { name: 'typcn-media-stop', code: '0xe0b6', match: 'media stop' },
  { name: 'typcn-message-typing', code: '0xe0b7', match: 'msg chat message typing' },
  { name: 'typcn-message', code: '0xe0b8', match: 'msg chat message' },
  { name: 'typcn-messages', code: '0xe0b9', match: 'msg chat messages' },
  { name: 'typcn-microphone-outline', code: '0xe0ba', match: 'record microphone outline' },
  { name: 'typcn-microphone', code: '0xe0bb', match: 'record microphone' },
  { name: 'typcn-minus-outline', code: '0xe0bc', match: 'subtract minus outline' },
  { name: 'typcn-minus', code: '0xe0bd', match: 'subtract minus' },
  { name: 'typcn-mortar-board', code: '0xe0be', match: 'mortar board' },
  { name: 'typcn-news', code: '0xe0bf', match: 'article news' },
  { name: 'typcn-notes-outline', code: '0xe0c0', match: 'music notes outline' },
  { name: 'typcn-notes', code: '0xe0c1', match: 'music notes' },
  { name: 'typcn-pen', code: '0xe0c2', match: 'write compose pen' },
  { name: 'typcn-pencil', code: '0xe0c3', match: 'write compose pencil' },
  { name: 'typcn-phone-outline', code: '0xe0c4', match: 'call ring phone outline' },
  { name: 'typcn-phone', code: '0xe0c5', match: 'call ring phone' },
  { name: 'typcn-pi-outline', code: '0xe0c6', match: 'pi outline' },
  { name: 'typcn-pi', code: '0xe0c7', match: 'pi' },
  { name: 'typcn-pin-outline', code: '0xe0c8', match: 'pin outline' },
  { name: 'typcn-pin', code: '0xe0c9', match: 'pin' },
  { name: 'typcn-pipette', code: '0xe0ca', match: 'picker pipette' },
  { name: 'typcn-plane-outline', code: '0xe0cb', match: 'flight plane outline' },
  { name: 'typcn-plane', code: '0xe0cc', match: 'flight plane' },
  { name: 'typcn-plug', code: '0xe0cd', match: 'connect plug' },
  { name: 'typcn-plus-outline', code: '0xe0ce', match: 'add plus outline' },
  { name: 'typcn-plus', code: '0xe0cf', match: 'add plus' },
  { name: 'typcn-point-of-interest-outline', code: '0xe0d0', match: 'command pos point of interest outline' },
  { name: 'typcn-point-of-interest', code: '0xe0d1', match: 'command pos point of interest' },
  { name: 'typcn-power-outline', code: '0xe0d2', match: 'on off power outline' },
  { name: 'typcn-power', code: '0xe0d3', match: 'on off power' },
  { name: 'typcn-printer', code: '0xe0d4', match: 'fax printer' },
  { name: 'typcn-puzzle-outline', code: '0xe0d5', match: 'jigsaw puzzle outline' },
  { name: 'typcn-puzzle', code: '0xe0d6', match: 'jigsaw puzzle' },
  { name: 'typcn-radar-outline', code: '0xe0d7', match: 'position radar outline' },
  { name: 'typcn-radar', code: '0xe0d8', match: 'position radar' },
  { name: 'typcn-refresh-outline', code: '0xe0d9', match: 'arrow refresh outline' },
  { name: 'typcn-refresh', code: '0xe0da', match: 'arrow refresh' },
  { name: 'typcn-rss-outline', code: '0xe0db', match: 'feed rss outline' },
  { name: 'typcn-rss', code: '0xe0dc', match: 'feed rss' },
  { name: 'typcn-scissors-outline', code: '0xe0dd', match: 'cut scissors outline' },
  { name: 'typcn-scissors', code: '0xe0de', match: 'cut scissors' },
  { name: 'typcn-shopping-bag', code: '0xe0df', match: 'shopping bag' },
  { name: 'typcn-shopping-cart', code: '0xe0e0', match: 'shopping cart' },
  { name: 'typcn-social-at-circular', code: '0xe0e1', match: '@ mail social at circular' },
  { name: 'typcn-social-dribbble-circular', code: '0xe0e2', match: 'social dribbble circular' },
  { name: 'typcn-social-dribbble', code: '0xe0e3', match: 'social dribbble' },
  { name: 'typcn-social-facebook-circular', code: '0xe0e4', match: 'social facebook circular' },
  { name: 'typcn-social-facebook', code: '0xe0e5', match: 'social facebook' },
  { name: 'typcn-social-flickr-circular', code: '0xe0e6', match: 'social flickr circular' },
  { name: 'typcn-social-flickr', code: '0xe0e7', match: 'social flickr' },
  { name: 'typcn-social-github-circular', code: '0xe0e8', match: 'social github circular' },
  { name: 'typcn-social-github', code: '0xe0e9', match: 'social github' },
  { name: 'typcn-social-google-plus-circular', code: '0xe0ea', match: null },
  { name: 'typcn-social-google-plus', code: '0xe0eb', match: 'social google plus' },
  { name: 'typcn-social-instagram-circular', code: '0xe0ec', match: 'social instagram circular' },
  { name: 'typcn-social-instagram', code: '0xe0ed', match: 'social instagram' },
  { name: 'typcn-social-last-fm-circular', code: '0xe0ee', match: 'social last fm circular' },
  { name: 'typcn-social-last-fm', code: '0xe0ef', match: 'social last fm' },
  { name: 'typcn-social-linkedin-circular', code: '0xe0f0', match: 'social linkedin circular' },
  { name: 'typcn-social-linkedin', code: '0xe0f1', match: 'social linkedin' },
  { name: 'typcn-social-pinterest-circular', code: '0xe0f2', match: 'social pinterest circular' },
  { name: 'typcn-social-pinterest', code: '0xe0f3', match: 'social pinterest' },
  { name: 'typcn-social-skype-outline', code: '0xe0f4', match: 'social skype outline' },
  { name: 'typcn-social-skype', code: '0xe0f5', match: 'social skype' },
  { name: 'typcn-social-tumbler-circular', code: '0xe0f6', match: 'social tumbler circular' },
  { name: 'typcn-social-tumbler', code: '0xe0f7', match: 'social tumbler' },
  { name: 'typcn-social-twitter-circular', code: '0xe0f8', match: 'social twitter circular' },
  { name: 'typcn-social-twitter', code: '0xe0f9', match: 'social twitter' },
  { name: 'typcn-social-vimeo-circular', code: '0xe0fa', match: 'social vimeo circular' },
  { name: 'typcn-social-vimeo', code: '0xe0fb', match: 'social vimeo' },
  { name: 'typcn-social-youtube-circular', code: '0xe0fc', match: 'social youtube circular' },
  { name: 'typcn-social-youtube', code: '0xe0fd', match: 'social youtube' },
  { name: 'typcn-sort-alphabetically-outline', code: '0xe0fe', match: 'sort outline' },
  { name: 'typcn-sort-alphabetically', code: '0xe0ff', match: 'a-z sort alphabetically' },
  { name: 'typcn-sort-numerically-outline', code: '0xe100', match: '123 sort numerically outline' },
  { name: 'typcn-sort-numerically', code: '0xe101', match: '123 sort numerically' },
  { name: 'typcn-spanner-outline', code: '0xe102', match: 'settings spanner outline' },
  { name: 'typcn-spanner', code: '0xe103', match: 'settings spanner' },
  { name: 'typcn-spiral', code: '0xe104', match: 'curve spiral' },
  { name: 'typcn-star-full-outline', code: '0xe105', match: 'star like outline' },
  { name: 'typcn-star-half-outline', code: '0xe106', match: 'star like outline' },
  { name: 'typcn-star-half', code: '0xe107', match: 'like favourite love rate star half' },
  { name: 'typcn-star-outline', code: '0xe108', match: 'like favourite love rate star outline' },
  { name: 'typcn-star', code: '0xe109', match: 'like favourite love rate star' },
  { name: 'typcn-starburst-outline', code: '0xe10a', match: 'banner ribbon starburst outline' },
  { name: 'typcn-starburst', code: '0xe10b', match: 'banner ribbon starburst' },
  { name: 'typcn-stopwatch', code: '0xe10c', match: 'time stopwatch' },
  { name: 'typcn-support', code: '0xe10d', match: 'life ring support' },
  { name: 'typcn-tabs-outline', code: '0xe10e', match: 'expand merge tabs outline' },
  { name: 'typcn-tag', code: '0xe10f', match: 'tag' },
  { name: 'typcn-tags', code: '0xe110', match: 'tags' },
  { name: 'typcn-th-large-outline', code: '0xe111', match: 'square grid th large outline' },
  { name: 'typcn-th-large', code: '0xe112', match: 'square grid th large' },
  { name: 'typcn-th-list-outline', code: '0xe113', match: 'thumbnail th list outline' },
  { name: 'typcn-th-list', code: '0xe114', match: 'thumbnail th list' },
  { name: 'typcn-th-menu-outline', code: '0xe115', match: 'th menu outline' },
  { name: 'typcn-th-menu', code: '0xe116', match: 'th menu' },
  { name: 'typcn-th-small-outline', code: '0xe117', match: 'square grid th small outline' },
  { name: 'typcn-th-small', code: '0xe118', match: 'square grid th small' },
  { name: 'typcn-thermometer', code: '0xe119', match: 'temperature thermometer' },
  { name: 'typcn-thumbs-down', code: '0xe11a', match: 'dislike thumbs down' },
  { name: 'typcn-thumbs-ok', code: '0xe11b', match: 'average thumbs ok' },
  { name: 'typcn-thumbs-up', code: '0xe11c', match: 'like thumbs up' },
  { name: 'typcn-tick-outline', code: '0xe11d', match: 'ok done correct tick outline' },
  { name: 'typcn-tick', code: '0xe11e', match: 'ok done correct tick' },
  { name: 'typcn-ticket', code: '0xe11f', match: 'pass ticket' },
  { name: 'typcn-time', code: '0xe120', match: 'watch clock time' },
  { name: 'typcn-times-outline', code: '0xe121', match: 'cross x times outline' },
  { name: 'typcn-times', code: '0xe122', match: 'cross x times' },
  { name: 'typcn-trash', code: '0xe123', match: 'garbage rubbish delete trash' },
  { name: 'typcn-tree', code: '0xe124', match: 'tree' },
  { name: 'typcn-upload-outline', code: '0xe125', match: 'upload outline' },
  { name: 'typcn-upload', code: '0xe126', match: 'upload' },
  { name: 'typcn-user-add-outline', code: '0xe127', match: 'person user add outline' },
  { name: 'typcn-user-add', code: '0xe128', match: 'person user add' },
  { name: 'typcn-user-delete-outline', code: '0xe129', match: 'person user delete outline' },
  { name: 'typcn-user-delete', code: '0xe12a', match: 'person user delete' },
  { name: 'typcn-user-outline', code: '0xe12b', match: 'person user outline' },
  { name: 'typcn-user', code: '0xe12c', match: 'person user' },
  { name: 'typcn-vendor-android', code: '0xe12d', match: 'vendor android' },
  { name: 'typcn-vendor-apple', code: '0xe12e', match: 'vendor apple' },
  { name: 'typcn-vendor-microsoft', code: '0xe12f', match: 'vendor microsoft' },
  { name: 'typcn-video-outline', code: '0xe130', match: 'video outline' },
  { name: 'typcn-video', code: '0xe131', match: 'video' },
  { name: 'typcn-volume-down', code: '0xe132', match: 'sound volume down' },
  { name: 'typcn-volume-mute', code: '0xe133', match: 'sound volume mute' },
  { name: 'typcn-volume-up', code: '0xe134', match: 'sound volume up' },
  { name: 'typcn-volume', code: '0xe135', match: 'sound volume' },
  { name: 'typcn-warning-outline', code: '0xe136', match: 'error alert warning outline' },
  { name: 'typcn-warning', code: '0xe137', match: 'error alert warning' },
  { name: 'typcn-watch', code: '0xe138', match: 'time watch' },
  { name: 'typcn-waves-outline', code: '0xe139', match: 'waves outline' },
  { name: 'typcn-waves', code: '0xe13a', match: 'waves' },
  { name: 'typcn-weather-cloudy', code: '0xe13b', match: 'weather cloudy' },
  { name: 'typcn-weather-downpour', code: '0xe13c', match: 'weather downpour' },
  { name: 'typcn-weather-night', code: '0xe13d', match: 'weather night' },
  { name: 'typcn-weather-partly-sunny', code: '0xe13e', match: 'weather partly sunny' },
  { name: 'typcn-weather-shower', code: '0xe13f', match: 'weather shower' },
  { name: 'typcn-weather-snow', code: '0xe140', match: 'weather snow' },
  { name: 'typcn-weather-stormy', code: '0xe141', match: 'weather stormy' },
  { name: 'typcn-weather-sunny', code: '0xe142', match: 'weather sunny' },
  { name: 'typcn-weather-windy-cloudy', code: '0xe143', match: 'weather windy cloudy' },
  { name: 'typcn-weather-windy', code: '0xe144', match: 'weather windy' },
  { name: 'typcn-wi-fi-outline', code: '0xe145', match: 'internet connection wi-fi wifi outline' },
  { name: 'typcn-wi-fi', code: '0xe146', match: 'internet connection wi-fi wifi' },
  { name: 'typcn-wine', code: '0xe147', match: 'drink beverage wine' },
  { name: 'typcn-world-outline', code: '0xe148', match: 'world outline' },
  { name: 'typcn-world', code: '0xe149', match: 'world' },
  { name: 'typcn-zoom-in-outline', code: '0xe14a', match: 'zoom in outline' },
  { name: 'typcn-zoom-in', code: '0xe14b', match: 'zoom in' },
  { name: 'typcn-zoom-out-outline', code: '0xe14c', match: 'zoom out outline' },
  { name: 'typcn-zoom-out', code: '0xe14d', match: 'zoom out' },
  { name: 'typcn-zoom-outline', code: '0xe14e', match: 'zoom outline' },
  { name: 'typcn-zoom', code: '0xe14f', match: 'zoom ' },
];

export default icons;
