import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import uuid from 'react-uuid';
import { useSnackbar } from 'notistack';

import { makeStyles } from '@material-ui/core/styles';
import colors from '../libs/colors';
import { getConfigHotspots } from '../redux/selectors';
import { setHotspots } from '../redux/actions';
import Label from './Label';
import Typcn from './Typcn';
import GradientButton from './GradientButton';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '0.15rem',
    marginBottom: '0.15rem',
  },
  buttonContainer: {
    display: 'flex',
  },
  input: {
    width: '100%',
    backgroundColor: colors.inputBackground,
    border: 'none',
    borderRadius: 3,
    fontFamily: 'Montserrat',
    color: colors.contrastText,
    lineHeight: '2rem',
    height: '2rem',
  },
  select: {
    width: '100%',
    padding: '0.25rem 0.5rem',
    margin: '0.25rem 0',
    border: 'none',
    fontWeight: 600,
    fontSize: 14,
    backgroundColor: colors.selectBackground,
    color: colors.contrastText,
    '& .MuiSelect-icon': {
      color: colors.contrastText,
    },
  },
  group: {
    margin: '0.5rem 0',
  },
  delete: {
    backgroundColor: colors.red,
    color: colors.contrastText,
    marginLeft: '0.25rem',
    minWidth: 28,
  },
  listItem: {
    alignItems: 'center',
    paddingLeft: '0.75rem',
    paddingRight: '0.25rem',
    backgroundColor: colors.inputBackground,
    borderRadius: 2,
    margin: '0.25rem 0',
  },
  listItemIcon: {
    color: colors.contrastText,
    minWidth: 36,
  },
  listItemText: {
    fontSize: 14,
    color: colors.contrastText,
  },
  white: {
    color: colors.contrastText,
  },
  chosedIcon: {
    fontSize: 14,
    color: colors.contrastText,
    display: 'flex',
    alignItems: 'center',
  },
}));

function HotspotEdit(props) {
  const classes = useStyles();
  const { hotspot = {}, onSave, onCancel } = props;

  const [valid, setValid] = useState(false);
  const [hot, setHot] = useState(hotspot);

  useEffect(() => {
    const title = hot.hotspotText && hot.hotspotText.length > 0;
    const icon = hot.hotspotIcon && hot.hotspotIcon.length > 0;

    setValid((title && icon));
  }, [hot, setValid]);

  const { enqueueSnackbar } = useSnackbar();
  const previewAvailable = hot.alpha || hot.beta || hot.radius;

  const renderIcon = (icon) => {
    if (!icon) {
      return null;
    }

    return (
      <div className={classes.chosedIcon}>
        <Label label="Current selected icon: " />
        <span className="icon icon-inline">
          <span className={`typcn icon-default ${icon}`} />
        </span>
      </div>
    );
  };

  return (
    <>
      <div className={classes.group}>
        <Label label="Feature Line" />
        <input
          className={classes.input}
          defaultValue={hot.hotspotText}
          placeholder="Insert the hotspot name here"
          onBlur={(event) => {
            const { value } = event.target;
            setHot({ ...hot, hotspotText: value });
          }}
        />
      </div>

      <div className={classes.group}>
        <Label
          label="Hotspot Icon"
          help="The icon that will appear beside the Feature Line hotspot name"
        />

        { renderIcon(hot.hotspotIcon) }

        <Typcn
          defaultValue={hot.hotspotIcon}
          onSelect={(iconName) => {
            setHot({ ...hot, hotspotIcon: iconName });
          }}
        />

      </div>

      <GradientButton
        margin="none"
        color="blue"
        disabled={!previewAvailable}
        onClick={() => {
          const {
            alpha,
            beta,
            radius,
            target,
          } = hot;

          window.camera.alpha = alpha;
          window.camera.beta = beta;
          window.camera.radius = radius;

          if (target !== undefined) {
            const { x, y, z } = target;

            window.camera.target.x = x;
            window.camera.target.y = y;
            window.camera.target.z = z;
          } else {
            window.camera.setTarget(window.defaultTarget);
            window.console.warn('Legacy hotspot found, setting to default target');
          }

          enqueueSnackbar('Camera moved into position', {
            variant: 'info',
          });
        }}
      >
        Preview Camera Position
      </GradientButton>
      <GradientButton
        margin="none"
        color="blue"
        onClick={() => {
          const {
            alpha,
            beta,
            radius,
            target,
          } = window.camera;
          const { x, y, z } = target;

          setHot({
            ...hot, alpha, beta, radius, target: { x, y, z },
          });

          enqueueSnackbar(`Successfully set the camera position for the hotspot "${hot.hotspotText}"`, {
            variant: 'success',
          });
        }}
      >
        Set Current Camera Position
      </GradientButton>
      <div className={classes.buttonContainer}>
        <GradientButton
          margin="right"
          color="green"
          disabled={!valid}
          onClick={() => {
            if (!hot.uuid) {
              hot.uuid = uuid();
              // hotspots.push(hotspot);

              // props.setHotspots(hotspots);
            } else {
              const keys = Object.keys(hotspot);
              keys.forEach((key) => {
                hotspot[key] = hotspot[key].valueOf();
              });
            }

            enqueueSnackbar(`Hotspot "${hot.hotspotText}" has been saved`, {
              variant: 'success',
            });

            if (onSave) {
              onSave(hot);
            }
          }}
        >
          Save Hotspot
        </GradientButton>

        <GradientButton
          margin="left"
          color="red"
          onClick={() => {
            enqueueSnackbar('Hotspot not saved, reverting changes', {
              variant: 'info',
            });

            if (onCancel) {
              onCancel();
            }
          }}
        >
          Cancel changes
        </GradientButton>
      </div>
    </>
  );
}

export default connect(
  (state) => ({ hotspots: getConfigHotspots(state) }),
  {
    setHotspots,
  },
)(HotspotEdit);
