import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import colors from '../libs/colors';
import { getConfigMultiple } from '../redux/selectors';
import { setConfigSingle } from '../redux/actions';
import Label from './Label';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '0.15rem',
    marginBottom: '0.15rem',
  },
  container: {
    display: 'flex',
    marginTop: '0.75rem',
    marginBottom: '0.75rem',
    justifyContent: 'space-between',
  },
  input: {
    width: '3.5rem',
    margin: '0 0.5rem',
    textAlign: 'center',
    backgroundColor: colors.inputBackground,
    border: 'none',
    borderRadius: 3,
    fontFamily: 'Montserrat',
    fontWeight: 600,
    color: colors.contrastText,
    lineHeight: '2rem',
    height: '2rem',
  },
  inputLast: {
    width: '3.5rem',
    margin: '0 0.5rem',
    textAlign: 'center',
    backgroundColor: colors.inputBackground,
    border: 'none',
    borderRadius: 3,
    fontFamily: 'Montserrat',
    fontWeight: 600,
    color: colors.contrastText,
    lineHeight: '2rem',
    height: '2rem',
    marginRight: 0,
  },
  preInput: {
    color: colors.contrastText,
    fontWeight: 600,
    fontSize: 14,
  },
  label: {
    fontSize: 14,
    color: colors.contrastText,
  },
}));

function MultiControl(props) {
  const classes = useStyles();
  const {
    label,
    help,
    mapping = [],
    initialValues = [],
    setConfigSingle: setConfSingle,
  } = props;

  const [values, setValues] = useState(initialValues);

  useEffect(() => {
    for (let index = 0; index < mapping.length; index++) {
      setConfSingle(mapping[index], values[index]);
    }
  }, [values, setConfSingle, mapping]);

  const RenderInput = (pos, index) => (
    <div key={pos} className={classes.root}>
      <span className={classes.preInput}>
        {pos.toUpperCase()}
        :
      </span>
      <input
        className={classes.input}
        type="number"
        value={initialValues[index]}
        onChange={(event) => {
          const { value } = event.target;
          values[index] = parseFloat(value);
          setConfSingle(mapping[index], values[index]);

          setValues(values);
        }}
      />
    </div>
  );

  // Color default value
  const { r, g, b } = initialValues[3];
  const hex = '#'.concat([r, g, b].map((v) => (v * 255).toString(16).padStart(2, '0')).join(''));

  return (
    <div className={classes.root}>
      <Label label={label} help={help} />
      <div className={classes.container}>
        {['x', 'y', 'z'].map(RenderInput)}
        <div>
          <input
            className={classes.inputLast}
            type="color"
            value={hex}
            onChange={(event) => {
              const { value } = event.target;
              // eslint-disable-next-line no-shadow
              const [r, g, b] = value.match(/[A-Za-z0-9]{2}/g).map((v) => (parseInt(v, 16) / 255));
              values[3] = { r, g, b };
              setConfSingle(mapping[3], values[3]);

              setValues(values);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default connect(
  (state, ownProps) => ({ initialValues: getConfigMultiple(state, ownProps.mapping) }),
  {
    setConfigSingle,
  },
)(MultiControl);
